import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useAlertWhenUserLeaves = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const message = `${t('alertWhenUserLeaves.startText')} ${t('contactPhoneNumber')} ${t('alertWhenUserLeaves.endText')}`;
      event.returnValue = message;
      return message;
    }

    window.addEventListener('beforeunload', beforeUnloadHandler, { capture: true });
  
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler, { capture: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
