import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import Error, { Props } from '../screens/Error/Error';
import { useAppDispatch } from '../store/hooks';

const ErrorContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);

  const props: Props = { actions };

  return <Error {...props} />;
};

export default ErrorContainer;
