import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';

import { AppActions } from '../../store/configureStore';
import { getQualifierList } from '../../shared/constants/qualifierOptions';
import DisqualifiedModal from '../DisqualifiedModal/DisqualifiedModal';
import ContainerFooter from '../ContainerFooter/ContainerFooter';
import qualifierSchema from '../../schemas/Qualifier';
import CustomButton from '../CustomButton/CustomButton';
import useEffectOnce from '../../utils/useEffectOnce';

export type Props = {
  actions: AppActions;
};

const NONE_APPLY_VALUE = 'None';

const initialValues = {
  checkboxCondition0: false,
  checkboxCondition1: false,
  checkboxCondition2: false,
  checkboxCondition3: false,
};

const ConditionListItemButton = styled(ListItemButton)(props => {
  const hoverProps = {
    backgroundColor: props.theme.palette.lightGreen.main,
    color: props.theme.palette.black.main,
  };
  return {
    '&.Mui-selected': {
      backgroundColor: props.theme.palette.green.main,
      color: props.theme.palette.white.main,
      '&:hover': hoverProps,
    },
    '&:hover': hoverProps,
    '@media (max-width: 600px)': {
      '&.Mui-selected': {
        backgroundColor: props.theme.palette.green.main,
        color: props.theme.palette.white.main,
        '&:hover': {
          backgroundColor: props.theme.palette.green.main,
          color: props.theme.palette.white.main,
        },
      },
      '&:hover': {
        backgroundColor: props.theme.palette.white.main,
        color: props.theme.palette.black.main,
      },
    },
  };
});

const QualifierForm = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const conditions = getQualifierList();
  const [checked, setChecked] = React.useState<string[]>([]);
  const [isDisqualifiedModalOpen, setIsDisqualifiedModalOpen] = React.useState<boolean>(false);
  useEffectOnce(() => props.actions.analyticsTrackPage('Qualifier'));

  const { handleSubmit, isSubmitting, resetForm } = useFormik({
    initialValues,
    validationSchema: qualifierSchema(),
    onSubmit: () => {
      const allSelected = checked.reduce((a, b) => (b ? `${a}|${b}` : `${a}`));
      props.actions.phoenixPostQualifiers(checked);
      if (checked.includes('None')) {
        navigate(`/vehicle-info`, { replace: true });
      } else if (checked.includes('CreditScoreisUnder510')) {
        navigate(`/disqualified-credit?selected=${allSelected}`, { replace: true });
      } else {
        setIsDisqualifiedModalOpen(true);
      }
    },
    validateOnMount: true,
  });

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked =
      value === NONE_APPLY_VALUE ? [] : checked.filter(item => item !== NONE_APPLY_VALUE);

    if (currentIndex === -1) {
      props.actions.analyticsTrackClick({ eventType: 'Qualifier Option Selected', option: value });
      newChecked.push(value);
    } else newChecked.splice(currentIndex, 1);

    setChecked(newChecked);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DisqualifiedModal
        open={isDisqualifiedModalOpen}
        onClick={() => {
          setChecked([]);
          resetForm();
          setIsDisqualifiedModalOpen(false);
        }}
        actions={props.actions}
        onReturn={() =>
          props.actions.phoenixPostProgressHistory({
            completedScreen: {
              screen: 'Qualifier',
              createdTimeUtc: new Date().toISOString(),
            },
            isVinEntered: false,
            nextScreen: 'Qualifier',
          })
        }
        options={checked}
      />
      <Stack spacing={1}>
        <List component="nav" key="nav-conditions">
          {conditions.map((condition, index) => {
            const labelId = `checkboxCondition${index}`;
            const isSelected = checked.indexOf(condition.value) !== -1;
            return (
              <Fragment key={`fragment-${condition.value}`}>
                <ConditionListItemButton
                  id={labelId}
                  key={labelId}
                  onClick={handleToggle(condition.value)}
                  selected={isSelected}
                >
                  <ListItemText id={`${labelId}-txt`} primary={condition.label} />
                  <ListItemIcon sx={{ justifyContent: 'right', minWidth: '30px' }}>
                    {isSelected && <CheckIcon sx={{ color: 'white.main' }} />}
                  </ListItemIcon>
                </ConditionListItemButton>
                <Divider />
              </Fragment>
            );
          })}
        </List>
      </Stack>
      <ContainerFooter>
        <CustomButton type="submit" isValid={!!checked.length} isSubmitting={isSubmitting}>
          {t('qualifier.continue')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default QualifierForm;
