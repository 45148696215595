import { useEffect } from 'react';
import { AppActions } from '../store/configureStore';
import { useNavigate } from 'react-router';

type Props = {
  actions: AppActions;
  estimatedOfferSelected: {
    data?: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    };
    errors?: { [key: string]: string };
  };
};

export const useGetEstimatedOfferSelected = ({ actions, estimatedOfferSelected }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const { data } = estimatedOfferSelected;
    if (!!data) {
      actions.setIsLoading(true);
      navigate('/off-ramp', { replace: true });
    }
  }, [actions, estimatedOfferSelected, navigate]);

  return [actions.phoenixPostEstimatedOffer];
};
