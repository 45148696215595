import React, { Fragment, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';

import ContainerFooter from '../ContainerFooter/ContainerFooter';
import getCreditScores from '../../shared/constants/creditScores';
import { AppActions } from '../../store/configureStore';
//import { getQualifierOptions } from '../../shared/constants/qualifierOptions';
import useEffectOnce from '../../utils/useEffectOnce';

export interface Props {
  actions: AppActions;
  creditScore?: {
    ficoDetails: { name: string; scoreType: string; score: string }[];
    dateReported: string;
    estimatedScore: number;
  };
  creditSummary?: {
    ficoDetails: { name: string; scoreType: string; score: string }[];
    loanDetails: {
      lender: string;
      currentBalance: string;
      openDate: string;
      payment: string;
      remainingTerm: string;
    }[];
  };
}

const ConditionListItemButton = styled(ListItemButton)(props => {
  const hoverProps = {
    backgroundColor: props.theme.palette.lightGreen.main,
    color: props.theme.palette.black.main,
  };
  return {
    '&.Mui-selected': {
      backgroundColor: props.theme.palette.green.main,
      color: props.theme.palette.white.main,
      '&:hover': hoverProps,
    },
    '&:hover': hoverProps,
    '@media (max-width: 600px)': {
      '&.Mui-selected': {
        backgroundColor: props.theme.palette.green.main,
        color: props.theme.palette.white.main,
        '&:hover': {
          backgroundColor: props.theme.palette.green.main,
          color: props.theme.palette.white.main,
        },
      },
      '&:hover': {
        backgroundColor: props.theme.palette.white.main,
        color: props.theme.palette.black.main,
      },
    },
  };
});

const EstimatedCreditScoreForm = ({ actions, creditScore, creditSummary }: Props) => {
  const [checked, setChecked] = React.useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const creditScores = getCreditScores();

  const handleToggle = (value: string) => () =>
    setChecked(checked.indexOf(value) > -1 ? '' : value);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      actions.phoenixPostCreditScore(checked);
    },
    [actions, checked]
  );

  useEffectOnce(() => actions.analyticsTrackPage('EstimatedCreditScore'));

  useEffect(() => {
    const { loanDetails = [] } = creditSummary || {};
    //const qualifierOptions = getQualifierOptions();
    if (creditScore?.estimatedScore) {
      //const { estimatedScore } = creditScore;
      /*if (estimatedScore < 510) {
        actions.phoenixPostQualifiers([qualifierOptions.CreditScoreisUnder510.value]);
        navigate('/disqualified-credit', { replace: true });
      } else if (
        !loanDetails.length ||
        !loanDetails.find(
          loan =>
            loan.lender &&
            loan.openDate &&
            loan.payment &&
            loan.currentBalance &&
            loan.remainingTerm
        )
      )
        navigate('/loan-info', { replace: true });
      else if (loanDetails.length) navigate('/loan-match', { replace: true });*/

              /*
        DX UI 1.1
        Based on initial release the gathered data showed it is creating a lot of drops on this page.
        Adding more credit score range for better leads
        */
      if (
        !loanDetails.length ||
        !loanDetails.find(
          loan =>
            loan.lender &&
            loan.openDate &&
            loan.payment &&
            loan.currentBalance &&
            loan.remainingTerm
        )
      )
        navigate('/loan-info', { replace: true });
      else if (loanDetails.length) navigate('/loan-match', { replace: true });
    }
  }, [actions, creditScore, navigate, creditSummary?.loanDetails?.length, creditSummary]);

  return (
    <>
      <Stack spacing={1}>
        <List component="nav" key="nav-credit-score">
          {Object.values(creditScores).map((score, index) => {
            const labelId = `checkboxCondition${index}`;
            const isSelected = checked.indexOf(score) !== -1;
            return (
              <Fragment key={`fragment-${score}`}>
                <ConditionListItemButton
                  id={labelId}
                  key={labelId}
                  onClick={handleToggle(score)}
                  selected={isSelected}
                >
                  <ListItemText id={`${labelId}-txt`} primary={score} />
                  <ListItemIcon sx={{ justifyContent: 'right' }}>
                    {isSelected && <CheckIcon sx={{ color: 'white.main' }} />}
                  </ListItemIcon>
                </ConditionListItemButton>
                <Divider />
              </Fragment>
            );
          })}
        </List>
      </Stack>
      <ContainerFooter>
        <Button
          variant="contained"
          type="submit"
          disabled={!checked.length}
          color={!checked.length ? 'neutral' : 'yellow'}
          sx={{ color: !checked.length ? 'darkGray.main' : 'black.main' }}
          style={{ marginTop: '181px' }}
          onClick={handleSubmit}
        >
          {t('estimatedCredit.continue')}
        </Button>
      </ContainerFooter>
    </>
  );
};

export default EstimatedCreditScoreForm;
