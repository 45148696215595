export type residenceType = {
  id: string;
  name: string;
};

export const residenceTypes: residenceType[] = [
  { id: '1', name: 'Mortgage' },
  { id: '2', name: 'Rent' },
  { id: '3', name: 'Family/Parents' },
  { id: '4', name: 'Owns Free and Clear' }
];

