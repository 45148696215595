import { getUrl, getRequestHeaders, logger, ApiResources, Methods, processResponse } from './utils';

export type CreditsData = {
  contactId: string;
  leadId: string;
};

export type CreditSummaryDetails = {
  ficoDetails: { name: string; scoreType: string; score: string }[];
  loanDetails: {
    lender: string;
    currentBalance: number;
    openDate: string;
    payment: number;
    remainingTerm: number;
  }[];
};

export type CreditSummaryError = { message: string; code: string; details: string };

export type CreditResponse = CreditSummaryDetails | CreditSummaryError;

export const getCreditSummary = async (payload: CreditsData): Promise<CreditResponse | null> => {
  const endpoint = getUrl(ApiResources.credits)
    .replace('{contactId}', payload.contactId)
    .replace('{leadId}', payload.leadId);
  const request = {
    method: Methods.get,
    headers: await getRequestHeaders(),
  };

  logger.log('getCreditSummary request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    return processResponse(response);
  } catch (err) {
    logger.error('getCreditSummary error', err);
    return null;
  }
};
