import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import ContainerFooter from '../ContainerFooter/ContainerFooter';
import CurrencyTextField from '../CurrencyTextField/CurrencyTextField';
import { onInputCurrency, onKeyDownCurrency } from '../../utils/input';
import CustomButton from '../CustomButton/CustomButton';
import { residenceTypes } from '../../shared/constants/residenceType';
import CurrentAddressSchema from '../../schemas/CurrentAddress';
import Logger from '../../libs/Logger';
import { getCurrentDate } from '../../utils/currentDate';
import { AddressData } from '../../services/phoenix';
import { AppActions } from '../../store/configureStore';

export type Props = {
  actions: AppActions;
  newaddress: {
    errors: { [key: string]: string };
    results: { [key: string]: string };
    contacts: Contacts;
  };
};

const logger = new Logger('CurrentAddressForm');

const NotCurrentAddress = styled.span<{ theme: Theme }>`
  color: ${({ theme }) => theme.palette.teal.main};
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  text-align: center;

  :hover {
    text-decoration: underline;
  }
`;
const initialValues: AddressData = {
  address: '',
  aptNumber: '',
  city: '',
  state: '',
  zipCode: '',
  moveinDate: '',
  residenceType: '',
  monthlyPayment: '',
};

type Contacts = {
  results: {
    address: '';
    aptNumber: '';
    city: '';
    state: '';
    zipCode: '';
    moveinDate: '';
    residenceType: '';
    monthlyPayment: '';
  };
};

const CurrentAddressForm = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: CurrentAddressSchema,
      onSubmit: values => {
        logger.log(values);
        props.actions.analyticsTrackFormSubmit({
          eventType: 'Current Address Form Submit',
          ...values,
        });
        props.actions.phoenixPostCurrentAddress(values);
      },
      validateOnMount: true,
    });
  useEffect(() => {
    props.actions.analyticsTrackPage('CurrentAddressForm');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField
          id="moveinDate"
          label={t('addressForm.form.moveinDate')}
          type="date"
          variant="filled"
          defaultValue="01/01/2023"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.moveinDate && !!errors.moveinDate}
          helperText={touched.moveinDate && errors.moveinDate}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: '01/01/2000',
            max: getCurrentDate(),
          }}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <FormControl
          variant="filled"
          error={touched.residenceType && !!errors.residenceType}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        >
          <InputLabel id="ResidenceType">{t('addressForm.form.residenceType')}</InputLabel>
          <Select
            id="residenceType"
            name="residenceType"
            value={values.residenceType}
            onChange={handleChange}
            onBlur={handleBlur}
            labelId="ResidenceType"
            sx={{
              bgcolor: 'lighterGray.main',
            }}
          >
            {residenceTypes.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.residenceType && errors.residenceType}</FormHelperText>
        </FormControl>
        <CurrencyTextField
          id="monthlyPayment"
          name="monthlyPayment"
          label={t('addressForm.form.monthlyPayment')}
          variant="filled"
          value={values.monthlyPayment}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDownCurrency}
          onInput={onInputCurrency}
          error={touched.monthlyPayment && !!errors.monthlyPayment}
          helperText={touched.monthlyPayment && errors.monthlyPayment}
          inputProps={{ maxLength: 10 }}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
      </Stack>
      <ContainerFooter>
        <NotCurrentAddress
          onClick={() => {
            navigate('/address');
          }}
        >
          {t('addressForm.form.notCurrentAddress')}
        </NotCurrentAddress>
        <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
          {t('addressForm.form.continueButton')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};
export default CurrentAddressForm;
