import * as Yup from 'yup';
import i18next from '../utils/i18n';

const schema = () =>
  Yup.object().shape({
    moveinDate: Yup.string().required(i18next.t('addressForm.schema.moveinDate') as string),
    monthlyPayment: Yup.string().required(i18next.t('addressForm.schema.monthlyPayment') as string),
    residenceType: Yup.string().required(i18next.t('addressForm.schema.residenceType') as string),
  });

export default schema;
