import { createAction } from 'redux-actions';

import ActionTypes from './actionTypes';

export const loadApp = createAction(ActionTypes.LOAD_APP);
export const launchApp = createAction(ActionTypes.LAUNCH_APP);
export const setIsLoading = createAction(ActionTypes.SET_IS_LOADING);
export const setIsLoadingNoWait = createAction(ActionTypes.SET_IS_LOADING_NO_WAIT);
export const setIsLoadingSuccess = createAction(ActionTypes.SET_IS_LOADING_SUCCESS);
export const resetAppError = createAction(ActionTypes.RESET_APP_ERROR);
export const setError = createAction(ActionTypes.SET_ERROR);
export const setRecaptchaToken = createAction(ActionTypes.SET_RECAPTCHA_TOKEN);

export const analyticsTrackPage = createAction(ActionTypes.ANALYTICS_TRACK_PAGE);
export const analyticsTrackClick = createAction(ActionTypes.ANALYTICS_TRACK_CLICK);
export const analyticsTrackFormSubmit = createAction(ActionTypes.ANALYTICS_TRACK_FORM_SUBMIT);
export const analyticsTrackSystemError = createAction(ActionTypes.ANALYTICS_TRACK_SYSTEM_ERROR);

export const phoenixPostContact = createAction(ActionTypes.PHOENIX_POST_CONTACT);
export const phoenixPostContactSuccess = createAction(ActionTypes.PHOENIX_POST_CONTACT_SUCCESS);
export const phoenixPostContactError = createAction(ActionTypes.PHOENIX_POST_CONTACT_ERROR);

export const phoenixPostQualifiers = createAction(ActionTypes.PHOENIX_POST_QUALIFIERS);
export const phoenixPostQualifiersSuccess = createAction(
  ActionTypes.PHOENIX_POST_QUALIFIERS_SUCCESS
);

export const phoenixGetCreditSummary = createAction(ActionTypes.PHOENIX_GET_CREDIT_SUMMARY);
export const phoenixGetCreditSummarySuccess = createAction(
  ActionTypes.PHOENIX_GET_CREDIT_SUMMARY_SUCCESS
);
export const phoenixGetCreditSummaryError = createAction(
  ActionTypes.PHOENIX_GET_CREDIT_SUMMARY_ERROR
);

export const phoenixGetYears = createAction(ActionTypes.PHOENIX_GET_YEARS);
export const phoenixGetYearsSuccess = createAction(ActionTypes.PHOENIX_GET_YEARS_SUCCESS);
export const phoenixGetYearsError = createAction(ActionTypes.PHOENIX_GET_YEARS_ERROR);

export const phoenixGetMakes = createAction(ActionTypes.PHOENIX_GET_MAKES);
export const phoenixGetMakesSuccess = createAction(ActionTypes.PHOENIX_GET_MAKES_SUCCESS);
export const phoenixGetMakesError = createAction(ActionTypes.PHOENIX_GET_MAKES_ERROR);

export const phoenixGetModels = createAction(ActionTypes.PHOENIX_GET_MODELS);
export const phoenixGetModelsSuccess = createAction(ActionTypes.PHOENIX_GET_MODELS_SUCCESS);
export const phoenixGetModelsError = createAction(ActionTypes.PHOENIX_GET_MODELS_ERROR);

export const clearVehicleInfo = createAction(ActionTypes.PHOENIX_CLEAR_VEHICLE_INFO);
export const phoenixGetVehicleInfoFromVIN = createAction(ActionTypes.PHOENIX_GET_VEHICLE_INFO);
export const phoenixGetVehicleInfoFromVINSuccess = createAction(
  ActionTypes.PHOENIX_GET_VEHICLE_INFO_SUCCESS
);
export const phoenixGetVehicleInfoFromVINError = createAction(
  ActionTypes.PHOENIX_GET_VEHICLE_INFO_ERROR
);

export const phoenixUpdateSSN = createAction(ActionTypes.PHOENIX_UPDATE_SSN);
export const phoenixUpdateSSNSuccess = createAction(ActionTypes.PHOENIX_UPDATE_SSN_SUCCESS);
export const phoenixUpdateSSNError = createAction(ActionTypes.PHOENIX_UPDATE_SSN_ERROR);

export const phoenixPostCreditScore = createAction(ActionTypes.PHOENIX_POST_CREDIT_SCORE);
export const phoenixPostCreditScoreSuccess = createAction(
  ActionTypes.PHOENIX_POST_CREDIT_SCORE_SUCCESS
);
export const phoenixPostCreditScoreError = createAction(
  ActionTypes.PHOENIX_POST_CREDIT_SCORE_ERROR
);
export const phoenixPutAsset = createAction(ActionTypes.PHOENIX_PUT_ASSET);
export const phoenixPutAssetSuccess = createAction(ActionTypes.PHOENIX_PUT_ASSET_SUCCESS);
export const phoenixPutAssetError = createAction(ActionTypes.PHOENIX_PUT_ASSET_ERROR);

export const phoenixPostAsset = createAction(ActionTypes.PHOENIX_POST_ASSET);
export const phoenixPostAssetSuccess = createAction(ActionTypes.PHOENIX_POST_ASSET_SUCCESS);
export const phoenixPostAssetError = createAction(ActionTypes.PHOENIX_POST_ASSET_ERROR);

export const phoenixPostAssets = createAction(ActionTypes.PHOENIX_POST_ASSETS);
export const phoenixPostAssetsSuccess = createAction(ActionTypes.PHOENIX_POST_ASSETS_SUCCESS);
export const phoenixPostAssetsError = createAction(ActionTypes.PHOENIX_POST_ASSETS_ERROR);

export const phoenixGetEstimatedOffers = createAction(ActionTypes.PHOENIX_GET_ESTIMATED_OFFERS);
export const phoenixGetEstimatedOffersSuccess = createAction(
  ActionTypes.PHOENIX_GET_ESTIMATED_OFFERS_SUCCESS
);
export const phoenixGetEstimatedOffersError = createAction(
  ActionTypes.PHOENIX_GET_ESTIMATED_OFFERS_ERROR
);

export const phoenixPostEstimatedOffer = createAction(ActionTypes.PHOENIX_POST_ESTIMATED_OFFER);
export const phoenixPostEstimatedOfferSuccess = createAction(
  ActionTypes.PHOENIX_POST_ESTIMATED_OFFER_SUCCESS
);
export const phoenixPostEstimatedOfferError = createAction(
  ActionTypes.PHOENIX_POST_ESTIMATED_OFFER_ERROR
);

export const phoenixGetProgressHistory = createAction(ActionTypes.PHOENIX_GET_PROGRESS_HISTORY);
export const phoenixGetProgressHistorySuccess = createAction(
  ActionTypes.PHOENIX_GET_PROGRESS_HISTORY_SUCCESS
);
export const phoenixGetProgressHistoryError = createAction(
  ActionTypes.PHOENIX_GET_PROGRESS_HISTORY_ERROR
);
export const phoenixPostProgressHistory = createAction(ActionTypes.PHOENIX_POST_PROGRESS_HISTORY);
export const phoenixPostProgressHistorySuccess = createAction(
  ActionTypes.PHOENIX_POST_PROGRESS_HISTORY_SUCCESS
);

export const phoenixPostLead = createAction(ActionTypes.PHOENIX_POST_LEAD);
export const phoenixPostLeadSuccess = createAction(ActionTypes.PHOENIX_POST_LEAD_SUCCESS);
export const phoenixPostLeadError = createAction(ActionTypes.PHOENIX_POST_LEAD_ERROR);

export const phoenixPostAddress = createAction(ActionTypes.PHOENIX_POST_ADDRESS);
export const phoenixPostAddressSuccess = createAction(ActionTypes.PHOENIX_POST_ADDRESS_SUCCESS);
export const phoenixPostAddressError = createAction(ActionTypes.PHOENIX_POST_ADDRESS_ERROR);

export const phoenixPostCurrentAddress = createAction(ActionTypes.PHOENIX_POST_CURRENT_ADDRESS);
export const phoenixPostCurrentAddressSuccess = createAction(
  ActionTypes.PHOENIX_POST_CURRENT_ADDRESS_SUCCESS
);
export const phoenixPostCurrentAddressError = createAction(
  ActionTypes.PHOENIX_POST_CURRENT_ADDRESS_ERROR
);
