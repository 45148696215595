import styled from "styled-components";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: #00aa8f;
  border-radius: 50%;
`;

const DisqualifiedList: React.FC<{ options: Array<string> }> = ({
  options,
}) => {
  return (
    <List>
      {options.map((option: string) => (
        <ListItem disablePadding key={option}>
          <ListItemIcon sx={{ width: 20, minWidth: 20 }}>
            <Dot />
          </ListItemIcon>
          <ListItemText primary={option} />
        </ListItem>
      ))}
    </List>
  );
};

export default DisqualifiedList;
