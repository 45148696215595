import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import AddressFormContainer from '../../containers/AddressFormContainer';
import Header from '../../components/Header/Header';
import StepProgress from '../../components/StepProgress/StepProgress';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';

const Address = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <ScreenContainer>
        <StepProgress size={4} />
        <Typography variant="h1">{t('addressForm.title')}</Typography>
        <p>{t('addressForm.currentAddress')}</p>
        <AddressFormContainer />
      </ScreenContainer>
    </>
  );
};

export default Address;
