import {
  getUrl,
  getRequestHeaders,
  logger,
  ApiResources,
  Methods,
  AssetResponse,
  processResponse,
} from './utils';

export type AssetDataPut = {
  estimatedMileage: string;
  assetId: string;
  leadId: string;
};

export const putAsset = async ({
  assetId,
  ...payload
}: AssetDataPut): Promise<AssetResponse | null> => {
  const endpoint = `${getUrl(ApiResources.assets)}/${assetId}`;
  const request = {
    method: Methods.put,
    headers: await getRequestHeaders(),
    body: JSON.stringify(payload),
  };

  logger.log('putAsset request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    return processResponse(response);
  } catch (err) {
    logger.error('putAsset error', err);
    return null;
  }
};
