declare const grecaptcha: {
  ready(callback: () => void): void;
  execute(siteKey: string, action: { action: string }): PromiseLike<string>;
};

export const callRecaptcha = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!grecaptcha) reject();
    grecaptcha.ready(() => {
      grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_KEY || '', { action: 'submit' })
        .then((token: string) => resolve(token));
    });
  });
};
