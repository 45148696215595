import {
  getUrl,
  getRequestHeaders,
  logger,
  ApiResources,
  Methods,
  VehicleOptionsResponse,
} from './utils';

export const getYears = async (): Promise<VehicleOptionsResponse | null> => {
  const endpoint = getUrl(ApiResources.years);
  const request = {
    method: Methods.get,
    headers: await getRequestHeaders(),
  };

  logger.log('getYears request', endpoint, request);
  try {
    const jsonResponse = await fetch(endpoint, request);
    const response = await jsonResponse.json();
    
    return response.reverse();
  } catch (err) {
    logger.error('getYears error', err);
    return null;
  }
};
