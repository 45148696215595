export default class Logger {

  static out(method: string, id: string, message: Array<unknown>) {
    if (process.env.ENV !== 'prod') {
      // @ts-ignore
      console[method](`%c[${id}]`, 'font-weight: bold;', ...message);
    }
  }
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  log = (...message: Array<unknown>) => Logger.out('log', this.id, message);

  warn = (...message: Array<unknown>) => Logger.out('warn', this.id, message);

  error = (...message: Array<unknown>) => Logger.out('error', this.id, message);

  debug = (...message: Array<unknown>) => Logger.out('debug', this.id, message);

  info = (...message: Array<unknown>) => Logger.out('info', this.id, message);
}