import { getUrl, getRequestHeaders, logger, ApiResources, Methods } from './utils';

export type QualifiersData = {
  leadId: string;
  contactId: string;
  disqualifiers: string[];
};

export type QualifiersResponse =
  | { contactId: string; leadId: string }
  | { message: string; errors: { key: string; value: string }[] };

const mapQualifiersPayload = (payload: QualifiersData) => ({
  leadId: payload.leadId,
  contactId: payload.contactId,
  disqualifiers: payload.disqualifiers,
});

export const postQualifiers = async (
  payload: QualifiersData
): Promise<QualifiersResponse | null> => {
  const endpoint = getUrl(ApiResources.qualifiers);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(mapQualifiersPayload(payload)),
  };
  logger.log('postQualifiers request', endpoint, request);

  try {
    const response = await (await fetch(endpoint, request)).json();
    return response;
  } catch (err) {
    logger.error('postQualifiers error', err);
    return null;
  }
};
