import Box from "@mui/material/Box";
import styled from "styled-components";

import logo from "../../assets/logo.png";

const AppHeader = styled.header`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 100%;
`;

const AppLogo = styled.img`
  height: 50%;
  pointer-events: none;
`;

const Header = () => (
  <Box
    sx={{
      boxShadow: 0,
      mb: ["1px", 2],
      borderBottom: "1px solid",
      borderColor: "neutral.main",
    }}
  >
    <AppHeader>
      <AppLogo src={logo} alt="logo" />
    </AppHeader>
  </Box>
);

export default Header;
