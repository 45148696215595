export function toDefaultPhoneFormat(source: string): string | null {
  //Filter only numbers from the input
  let cleaned = ('' + source).replace(/\D/g, '');

  //Check if the input is of correct
  let match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Two main reasons to hardcoded country code:
    // API always return phone numbers in flat format without country code
    // MVP just target US citizens
    return `+1 (${match[2]}) ${match[3]}-${match[4]}`;
  }

  return null;
}
