import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';
import { Map } from 'immutable';

import reducers from './reducers';

import rootSaga from '../sagas/index';

import * as Actions from '../actions/actions';

const configureStore = (preloadedState: {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const composedEnhancers = composeWithDevTools({})(middlewareEnhancer);

  const store = createStore(reducers, preloadedState, composedEnhancers);

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;

export const store = configureStore({});

export type AppDispatch = typeof store.dispatch;

export type AppActions = typeof Actions;

export type RootState = ReturnType<typeof reducers>; // store.getState

export type RootStateImmutable = Map<string, unknown>;

export type PayloadAction<P = void, T extends string = string> = {
  payload: P;
  type: T;
};
