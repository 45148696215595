import { getUrl, getRequestHeaders, logger, ApiResources, Methods, processResponse } from './utils';

export type LeadsResponse =
  | { leadId: string; leadType: string }
  | { message: string; errors: { key: string; value: string }[] };

type PostLeadsPayload = {
  contactId: string;
};

export const postLeads = async ({ contactId }: PostLeadsPayload): Promise<LeadsResponse | null> => {
  const endpoint = `${getUrl(ApiResources.leads)}/${contactId}`;
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
  };

  logger.log('postLead request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    return processResponse(response);
  } catch (err) {
    logger.error('postLead error', err);
    return null;
  }
};
