import i18next from '../../utils/i18n';

export type Milleage = {
  id: string;
  name: string;
};

export const getMileages = (): Milleage[] => {
  const milesText = i18next.t('vehicleMoreInfo.miles');
  const toText = i18next.t('vehicleMoreInfo.to');
  return [
    { id: '0-10,000', name: `0 ${toText} 10,000 ${milesText}` },
    { id: '10,000-20,000', name: `10,001 ${toText} 20,000 ${milesText}` },
    { id: '20,000-30,000', name: `20,001 ${toText} 30,000 ${milesText}` },
    { id: '30,000-40,000', name: `30,001 ${toText} 40,000 ${milesText}` },
    { id: '40,000-50,000', name: `40,001 ${toText} 50,000 ${milesText}` },
    { id: '50,000-60,000', name: `50,001 ${toText} 60,000 ${milesText}` },
    { id: '60,000-70,000', name: `60,001 ${toText} 70,000 ${milesText}` },
    { id: '70,000-80,000', name: `70,001 ${toText} 80,000 ${milesText}` },
    { id: '80,000-90,000', name: `80,001 ${toText} 90,000 ${milesText}` },
    { id: '90,000-100,000', name: `90,001 ${toText} 100,000 ${milesText}` },
    { id: '100,000-110,000', name: `100,001 ${toText} 110,000 ${milesText}` },
    { id: '110,000-120,000', name: `110,001 ${toText} 120,000 ${milesText}` },
    { id: '120,000-130,000', name: `120,001 ${toText} 130,000 ${milesText}` },
    { id: '130,000-140,000', name: `130,001 ${toText} 140,000 ${milesText}` },
    { id: '140,000-150,000', name: `140,001 ${toText} 150,000 ${milesText}` },
    { id: 'Over 150,000 miles', name: `${i18next.t('vehicleMoreInfo.over')} 150,000 ${milesText}` },
  ];
};

export const isOver150miles = (mileage: string) =>
  mileage === `${i18next.t('vehicleMoreInfo.over')} 150,000 ${i18next.t('vehicleMoreInfo.miles')}`;
