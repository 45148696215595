import React from 'react';
import { IMaskInput } from 'react-imask';
import SSNMask from '../../utils/SSNMask';

interface SSNMaskCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
  unmaskedValue: string;
}

const SSNMaskCustom = React.forwardRef<
  HTMLInputElement,
  SSNMaskCustomProps
>((props, ref) => {
  const { onChange, value, ...other } = props;

  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask='###-##-####'
      unmask={true}
      definitions={{
        '#': SSNMask
      }}
      onAccept={(unmaskedValue: unknown) => {
        return onChange({
          target: {
            name: props.name,
            value: unmaskedValue as string 
          }
        })
      }}
      overwrite
      value={value}
    />
  );
});

export default SSNMaskCustom;
