import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    black?: PaletteColorOptions;
    white?: PaletteColorOptions;
    gray?: PaletteColorOptions;
    lightGray?: PaletteColorOptions;
    lighterGray?: PaletteColorOptions;
    darkGray?: PaletteColorOptions;
    darkerGray?: PaletteColorOptions;
    green?: PaletteColorOptions;
    lightGreen?: PaletteColorOptions;
    lighterGreen?: PaletteColorOptions;
    neutral?: PaletteColorOptions;
    teal?: PaletteColorOptions;
    yellow?: PaletteColorOptions;
    blue?: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    teal: true;
    yellow: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    green: true;
  }
}

declare module '@mui/icons-material' {
  interface InfoPropsColorOverrides {
    teal: true;
  }
}

const palette = {
  text: {
    disabled: '#8a8c8d',
    primary: '#181a1a',
    secondary: '#7c7e7e',
  },
  action: {
    active: '#8a8c8d',
  },
  neutral: {
    main: '#e1e2e2',
  },
  teal: {
    main: '#2c5a7a',
  },
  black: {
    main: '#181a1a',
  },
  white: {
    main: '#ffffff',
  },
  gray: {
    main: '#7c7e7e',
  },
  lightGray: {
    main: '#ebebeb',
  },
  lighterGray: {
    main: '#f4f6f8',
    dark: '#e8eaec',
  },
  darkGray: {
    main: '#8a8c8d',
  },
  darkerGray: {
    main: '#181a1a',
  },
  green: {
    main: '#00aa8f',
  },
  lightGreen: {
    main: '#bfe9e3',
  },
  lighterGreen: {
    main: '#f2faf9',
  },
  yellow: {
    main: '#f0bf3f',
  },
  blue: {
    main: '#1a73e8',
  },
};

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'Century Gothic Pro',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Century Gothic Pro',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: 0.15,
    },
    h2: {
      fontFamily: 'Century Gothic Pro',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '26px',
      letterSpacing: 0.15,
    },
    h3: {
      fontFamily: 'Century Gothic Pro',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: 0.15,
    },
    button: {
      fontSize: 16,
      lineHeight: '26px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          color: palette.black.main,
          fontWeight: 700,
          height: 42,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.black.main,
          fontFamily: 'Open Sans',
          fontSize: 12,
          fontWeight: 400,
          lineHeight: '14px',
          padding: '4px 8px',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: palette.teal.main,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'green',
      },
    },
  },
  palette,
});

export default theme;
