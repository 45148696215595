import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQuerystring = () => {
  const { search } = useLocation();
  const query = useMemo(() => {
    let paramsObj: { [key: string]: string } = {};
    new URLSearchParams(search).forEach((item, key) => (paramsObj[key] = item));
    return paramsObj;
  }, [search]);

  return query;
};

export default useQuerystring;
