import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import VehicleNoVINInfoForm, {
  Props,
} from '../components/VehicleNoVINInfoForm/VehicleNoVINInfoForm';
import { useAppSelector, useAppDispatch } from '../store/hooks';

const VehicleNoVINInfoFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    vehicleInfoOptions: phoenixRoot.vehicleInfoOptions as Props['vehicleInfoOptions'],
    actions,
  };

  return <VehicleNoVINInfoForm {...props} />;
};

export default VehicleNoVINInfoFormContainer;
