import { ElementType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import PhoneMaskCustom from './PhoneMaskInput';
import { Typography } from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase';

const PhoneTextField = (props: TextFieldProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <TextField
      id="phoneNumber"
      name="phoneNumber"
      variant="filled"
      label={
        isFocused || !!props.value ? (
          t('aboutYou.form.phone')
        ) : (
          <>
            <Typography color={!!props.helperText ? 'error.main' : 'black.main'} component="span">
              +1
            </Typography>{' '}
            (555) 555-5555 *
          </>
        )
      }
      {...props}
      InputProps={{
        inputComponent: PhoneMaskCustom as unknown as ElementType<InputBaseComponentProps>,
        ...props.InputProps,
      }}
      onFocus={e => {
        setIsFocused(true);
        if (props.onFocus) props.onFocus(e);
      }}
      onBlur={e => {
        setIsFocused(false);
        if (props.onBlur) props.onBlur(e);
      }}
    />
  );
};

export default PhoneTextField;
