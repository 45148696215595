import { useEffect } from 'react';
import { useFormik } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

import ContainerFooter from '../ContainerFooter/ContainerFooter';
import CurrencyTextField from '../CurrencyTextField/CurrencyTextField';
import { onInputCurrency, onKeyDownCurrency, onInputNumbersOnly } from '../../utils/input';
import CustomButton from '../CustomButton/CustomButton';
import { countryStates } from '../../shared/constants/countryStates';
import { residenceTypes } from '../../shared/constants/residenceType';
import AddressSchema from '../../schemas/Address';
import Logger from '../../libs/Logger';
import { getCurrentDate } from '../../utils/currentDate';
import { AddressData } from '../../services/phoenix';
import { AppActions } from '../../store/configureStore';

export type Props = {
  actions: AppActions;
  newaddress: {
    errors: { [key: string]: string };
    results: { [key: string]: string };
  };
};

const logger = new Logger('AddressForm');

const initialValues: AddressData = {
  address: '',
  aptNumber: '',
  city: '',
  state: '',
  zipCode: '',
  moveinDate: '',
  residenceType: '',
  monthlyPayment: '',
};
const AddressForm = (props: Props) => {
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: AddressSchema,
      onSubmit: values => {
        logger.log(values);
        props.actions.analyticsTrackFormSubmit({ eventType: 'NewAddress Form Submit', ...values });
        props.actions.phoenixPostAddress(values);
      },
      validateOnMount: true,
    });
  useEffect(() => {
    props.actions.analyticsTrackPage('NewAddress');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField
          id="address"
          name="address"
          label={t('addressForm.form.address')}
          variant="filled"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.address && !!errors.address}
          helperText={touched.address && errors.address}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <TextField
          id="aptNumber"
          name="aptNumber"
          label={t('addressForm.form.aptNumber')}
          variant="filled"
          value={values.aptNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.aptNumber && !!errors.aptNumber}
          helperText={touched.aptNumber && errors.aptNumber}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <TextField
          id="city"
          name="city"
          label={t('addressForm.form.city')}
          variant="filled"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.city && !!errors.city}
          helperText={touched.city && errors.city}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <FormControl
          variant="filled"
          error={touched.state && !!errors.state}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        >
          <InputLabel id="stateLabel">{t('addressForm.form.state')}</InputLabel>
          <Select
            id="state"
            name="state"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
            labelId="stateLabel"
            sx={{
              bgcolor: 'lighterGray.main',
            }}
          >
            {countryStates.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.state && errors.state}</FormHelperText>
        </FormControl>
        <TextField
          id="zipCode"
          name="zipCode"
          label={t('addressForm.form.zipCode')}
          variant="filled"
          value={values.zipCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.zipCode && !!errors.zipCode}
          helperText={touched.zipCode && errors.zipCode}
          inputProps={{ maxLength: 5 }}
          onInput={onInputNumbersOnly}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <TextField
          id="moveinDate"
          label={t('addressForm.form.moveinDate')}
          type="date"
          variant="filled"
          defaultValue="01/01/2023"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.moveinDate && !!errors.moveinDate}
          helperText={touched.moveinDate && errors.moveinDate}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: '01/01/2000',
            max: getCurrentDate(),
          }}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <FormControl
          variant="filled"
          error={touched.residenceType && !!errors.residenceType}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        >
          <InputLabel id="ResidenceType">{t('addressForm.form.residenceType')}</InputLabel>
          <Select
            id="residenceType"
            name="residenceType"
            value={values.residenceType}
            onChange={handleChange}
            onBlur={handleBlur}
            labelId="ResidenceType"
            sx={{
              bgcolor: 'lighterGray.main',
            }}
          >
            {residenceTypes.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.residenceType && errors.residenceType}</FormHelperText>
        </FormControl>
        <CurrencyTextField
          id="monthlyPayment"
          name="monthlyPayment"
          label={t('addressForm.form.monthlyPayment')}
          variant="filled"
          value={values.monthlyPayment}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDownCurrency}
          onInput={onInputCurrency}
          error={touched.monthlyPayment && !!errors.monthlyPayment}
          helperText={touched.monthlyPayment && errors.monthlyPayment}
          inputProps={{ maxLength: 10 }}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
      </Stack>
      <ContainerFooter>
        <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
          {t('addressForm.form.continueButton')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default AddressForm;
