import React from 'react';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import DisqualifiedList from '../../components/DisqualifiedList/DisqualifiedList';
import Header from '../../components/Header/Header';
import { QualifierOption, getQualifierOptions } from '../../shared/constants/qualifierOptions';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/actions';
import { useAppDispatch } from '../../store/hooks';
import { AppActions } from '../../store/configureStore';
import useEffectOnce from '../../utils/useEffectOnce';

const PhoneNumber = styled.a`
  cursor: grab;
  text-decoration: none;
`;

function useQuery(param: string) {
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  return query.get(param);
}

const Disqualified = () => {
  const { t } = useTranslation();
  const qualifierOptions = getQualifierOptions();
  const dispatch = useAppDispatch();
  const actions: AppActions = bindActionCreators(Actions, dispatch);
  const selected = useQuery('selected');
  const options: QualifierOption[] | undefined = selected
    ?.split('|')
    .map((option: string) => qualifierOptions[option]);

  useEffectOnce(() => actions.analyticsTrackPage('DisqualifiedVehicle'));

  const onPhoneClick = () =>
    actions.analyticsTrackClick({ eventType: 'Soft Decline Phone Number Clicked' });

  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('disqualified.title')}</Typography>
        <p>{t('disqualified.startText')}</p>
        <DisqualifiedList options={options || []} />
        <p>
          {t('disqualified.endText')}
          <PhoneNumber
            href={'tel:' + t('contactPhoneNumber').replace(/-/g, '')}
            onClick={onPhoneClick}
          >
            {t('contactPhoneNumber')}
          </PhoneNumber>
          .
        </p>
      </ScreenContainer>
    </>
  );
};

export default Disqualified;
