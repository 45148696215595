import { takeLatest, put, all, select, delay } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import ActionTypes from '../actions/actionTypes';
import * as actions from '../actions/actions';
import * as selectors from './selectors';

import Logger from '../libs/Logger';
import { RootState } from '../store/configureStore';
import { loadingImageList } from '../utils/useShowLoadingAnimation';
import { LOADING_WAIT_IN_MS } from '../shared/constants/misc';

const logger = new Logger('appSaga');

function* loadApp() {
  const appData: RootState = yield select(selectors.appRoot);
  logger.log('Loading app -> ', appData);
  yield put(actions.setIsLoading({ isLoading: true }));
  yield put(actions.launchApp());
}

function* startApp() {
  const appData: RootState = yield select(selectors.appRoot);
  logger.log('App loaded -> ', appData);
  yield put(actions.setIsLoading({ isLoading: false }));
}

let loadingImagetempList = [...loadingImageList];
const getRandomImage = () => {
  const index = Math.floor(Math.random() * (loadingImagetempList.length - 1));
  const [image] = loadingImagetempList.splice(index, 1);
  if (loadingImagetempList.length === 0) loadingImagetempList = [...loadingImageList];
  return image;
};

function* setIsLoading({ payload }: AnyAction) {
  if (payload.isLoading) yield delay(LOADING_WAIT_IN_MS);
  yield put(actions.setIsLoadingNoWait(payload));
}

function* setIsLoadingNoWait({ payload }: AnyAction) {
  yield put(
    actions.setIsLoadingSuccess({
      isLoading: payload.isLoading,
      ...(payload.isLoading ? { loadingImage: getRandomImage() } : {}),
    })
  );
}

function* appSaga() {
  yield all([
    takeLatest(ActionTypes.LOAD_APP, loadApp),
    takeLatest(ActionTypes.LAUNCH_APP, startApp),
    takeLatest(ActionTypes.SET_IS_LOADING, setIsLoading),
    takeLatest(ActionTypes.SET_IS_LOADING_NO_WAIT, setIsLoadingNoWait),
  ]);
}

export default appSaga;
