import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import { useAppDispatch } from '../store/hooks';

import FailRecaptcha from '../components/FailRecaptcha/FailRecaptcha';

import type { FailRecaptchaProps } from '../components/FailRecaptcha/FailRecaptcha';

const OffRampContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);

  const props: FailRecaptchaProps = {
    actions,
  };

  return <FailRecaptcha {...props} />;
};

export default OffRampContainer;
