import { useEffect } from 'react';
import { AppActions } from '../store/configureStore';
import { useNavigate } from 'react-router';

type Props = {
  actions: AppActions;
  estimatedOffers: {
    data?: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    }[];
    errors?: { [key: string]: string };
  };
};

export const useGetEstimatedOffers = ({ actions, estimatedOffers }: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    const { data, errors } = estimatedOffers;

    if (data) {
      if (data.length <= 1) {
        navigate('/off-ramp', { replace: true });
      } else {
        navigate('/estimated-offers', { replace: true });
      }
    } else if (errors) {
      navigate('/off-ramp', { replace: true });
    }
  }, [actions, estimatedOffers, navigate]);

  return [actions.phoenixGetEstimatedOffers];
};
