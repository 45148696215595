import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import { useAppSelector, useAppDispatch } from '../store/hooks';

import OffRampResult from '../components/OffRampResult/OffRampResult';

import type { OffRampProps } from '../components/OffRampResult/OffRampResult';

const OffRampContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: OffRampProps = {
    actions,
    estimatedOfferSelected: phoenixRoot.estimatedOfferSelected as OffRampProps['estimatedOfferSelected'],
  };

  return <OffRampResult {...props} />
};

export default OffRampContainer;
