import Immutable from 'immutable';
import { CombinedState, combineReducers, Reducer } from 'redux';
import { Action } from 'redux-actions';

import app, { selectors as appSelectors } from './app';
import phoenix, { selectors as phoenixSelectors } from './phoenix';

export interface RootState {
  app?: Immutable.Map<string, unknown>;
  phoenix?: Immutable.Map<string, unknown>;
}

const reducers = {
  app,
  phoenix,
};

export default combineReducers(reducers) as unknown as Reducer<
  CombinedState<RootState>,
  Action<string>
>;

export { appSelectors, phoenixSelectors };
