import {
  getUrl,
  getRequestHeaders,
  logger,
  ApiResources,
  Methods,
  VehicleOptionsResponse,
} from './utils';

export const getMakes = async (year: string): Promise<VehicleOptionsResponse | null> => {
  const endpoint = `${getUrl(ApiResources.makes)}/${year}`;
  const request = {
    method: Methods.get,
    headers: await getRequestHeaders(),
  };

  logger.log('getMakes request', endpoint, request);
  try {
    const jsonResponse = await fetch(endpoint, request);
    const response = await jsonResponse.json();

    return response;
  } catch (err) {
    logger.error('getMakes error', err);
    return null;
  }
};
