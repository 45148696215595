import { getUrl, getRequestHeaders, logger, ApiResources, Methods, processResponse } from './utils';

export type EstimatedOffersRequestData = {
  leadId: string;
  contactId: string;
};

export type EstimatedOffersSummary = {
  dealId: string;
  rate: number;
  monthlyPaymentAmount: number;
  term: number;
  offerType: string;
}[];

export type EstimatedOffersError = { message: string; code: string; details: string };

export type EstimatedOffersResponse = EstimatedOffersSummary | EstimatedOffersError;

export const getEstimatedOffers = async (
  payload: EstimatedOffersRequestData
): Promise<EstimatedOffersResponse | null> => {
  const endpoint = getUrl(ApiResources.estimatedOffers);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(payload),
  };
  logger.log('getEstimatedOffers request', endpoint, request);

  try {
    const response = await fetch(endpoint, request);
    return processResponse(response);
  } catch (err) {
    logger.error('getEstimatedOffers error', err);
    return null;
  }
};
