import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import ContainerFooter from '../ContainerFooter/ContainerFooter';
import CustomButton from '../CustomButton/CustomButton';
import vehicleNoVINInfo from '../../schemas/VehicleNoVINInfo';
import { getMileages, isOver150miles } from '../../shared/constants/mileages';
import { AppActions } from '../../store/configureStore';
import { getQualifierOptions } from '../../shared/constants/qualifierOptions';
import useEffectOnce from '../../utils/useEffectOnce';

interface VehicleInfoOption {
  id: number;
  name: string;
}

export type Props = {
  actions: AppActions;
  vehicleInfoOptions: {
    years: VehicleInfoOption[];
    makes: VehicleInfoOption[];
    models: VehicleInfoOption[];
    errors: { [key: string]: string };
  };
};

const initialValues = {
  year: '',
  make: '',
  model: '',
  mileage: '',
};

const VehicleInfoForm = ({
  actions,
  vehicleInfoOptions: { years, makes, models, errors: apiErrors },
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const qualifierOptions = getQualifierOptions();
  const mileages = getMileages();
  const isOver10years = (year: string) => +year <= new Date().getFullYear() - 10;

  useEffectOnce(() => {
    actions.analyticsTrackPage('VehicleNoVin');
    actions.phoenixGetYears();
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: vehicleNoVINInfo(),
    onSubmit: values => {
      const { mileage, year } = values;
      const assetIsOver10years = isOver10years(year.toString());
      const assetIsOver150miles = isOver150miles(mileage.toString());

      actions.phoenixPostAssets({
        estimatedMileageRange: mileage,
        make: makes.find(make => make.id === +values.make)?.name,
        model: models.find(model => model.id === +values.model)?.name,
        year: year,
      });

      if (assetIsOver10years || assetIsOver150miles) {
        const options = [
          ...(!assetIsOver10years ? [] : [qualifierOptions.VehicleIsOver10.value]),
          ...(!assetIsOver150miles ? [] : [qualifierOptions.Over150kMiles.value]),
        ];
        const allSelected = options.reduce((a, b) => (b ? `${a}|${b}` : `${a}`));
        actions.phoenixPostQualifiers(options);
        navigate(`/disqualified?selected=${allSelected}`, { replace: true });
      } else if (!assetIsOver10years && !assetIsOver150miles) {
        navigate('/request-ssn', { replace: true });
      }
    },
    validateOnMount: true,
  });

  return (
    <form onSubmit={handleSubmit} name="noVinForm">
      <Stack spacing={2}>
        <FormControl
          variant="filled"
          error={touched.year && !!errors.year}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        >
          <InputLabel id="yearLabel">{t('vehicleNoVin.form.year')}</InputLabel>
          <Select
            id="year"
            name="year"
            value={values.year}
            onChange={async e => {
              actions.phoenixGetMakes(e.target.value);
              await Promise.all([setFieldValue('make', ''), setFieldValue('model', '')]);
              handleChange(e);
            }}
            onBlur={handleBlur}
            labelId="yearLabel"
            sx={{
              bgcolor: 'lighterGray.main',
            }}
          >
            {years.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.year && errors.year}</FormHelperText>
        </FormControl>
        <FormControl
          variant="filled"
          disabled={!makes || !makes.length}
          error={touched.make && !!errors.make}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        >
          <InputLabel id="makeLabel">{t('vehicleNoVin.form.make')}</InputLabel>
          <Select
            id="make"
            name="make"
            value={values.make}
            onChange={async e => {
              actions.phoenixGetModels({ make: e.target.value, year: values.year });
              await setFieldValue('model', '');
              handleChange(e);
            }}
            onBlur={handleBlur}
            labelId="makeLabel"
            sx={{
              bgcolor: 'lighterGray.main',
            }}
          >
            {makes.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.make && errors.make}</FormHelperText>
        </FormControl>
        <FormControl
          variant="filled"
          disabled={!models || !models.length}
          error={touched.model && !!errors.model}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        >
          <InputLabel id="modelLabel">{t('vehicleNoVin.form.model')}</InputLabel>
          <Select
            id="model"
            name="model"
            value={values.model}
            onChange={handleChange}
            onBlur={handleBlur}
            labelId="modelLabel"
            sx={{
              bgcolor: 'lighterGray.main',
            }}
          >
            {models.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.model && errors.model}</FormHelperText>
        </FormControl>
        <FormControl
          variant="filled"
          error={touched.mileage && !!errors.mileage}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        >
          <InputLabel id="mileageLabel">{t('vehicleNoVin.form.mileage')}</InputLabel>
          <Select
            id="mileage"
            name="mileage"
            value={values.mileage}
            onChange={handleChange}
            onBlur={handleBlur}
            labelId="mileageLabel"
            sx={{
              bgcolor: 'lighterGray.main',
            }}
          >
            {mileages.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.mileage && errors.mileage}</FormHelperText>
        </FormControl>
      </Stack>
      <ContainerFooter>
        <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
          {t('vehicleNoVin.form.continue')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default VehicleInfoForm;
