import * as Yup from 'yup';

import i18next from '../utils/i18n';

const schema = () => {
  const vinErrorMessage = i18next.t('vehicleInfo.vinError') as string;
  return Yup.object().shape({
    vin: Yup.string().required(vinErrorMessage).min(17, vinErrorMessage).max(17, vinErrorMessage),
  });
};

export default schema;
