import { RootState } from '../store/configureStore';
import { appSelectors, phoenixSelectors } from '../store/reducers';

export const rootState = (state: RootState) => state;

export const appRoot = (state: RootState) => appSelectors.appRoot(state);

export const appUi = (state: RootState) => appSelectors.appRoot(state).ui;

export const phoenixRoot = (state: RootState) => phoenixSelectors.phoenixRoot(state);
