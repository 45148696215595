import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import AboutYouForm, { Props } from '../components/AboutYouForm/AboutYouForm';
import { useAppSelector, useAppDispatch } from '../store/hooks';

const AboutYouFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    contacts: phoenixRoot.contacts as Props['contacts'],
    actions,
  };

  return <AboutYouForm {...props} />;
};

export default AboutYouFormContainer;
