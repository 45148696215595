import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AppActions } from '../store/configureStore';
import { getQualifierOptions } from '../shared/constants/qualifierOptions';

type Props = {
  actions: AppActions;
  vehicleInfo: {
    data?: { [key: string]: string };
    errors?: { [key: string]: string };
    loading?: boolean;
  };
  setIsDisqualified?: () => void;
};

export const useGetVehicleInfo = ({ actions, vehicleInfo, setIsDisqualified }: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (vehicleInfo.data) {
      if (vehicleInfo.data?.isVehicle10YearsOrOlder) {
        const qualifierOptions = getQualifierOptions();
        const options = [qualifierOptions.VehicleIsOver10.value];
        const allSelected = options.reduce((a, b) => (b ? `${a}|${b}` : `${a}`));
        actions.phoenixPostQualifiers(options);
        navigate(`/disqualified?selected=${allSelected}`, { replace: true });
      } else if (vehicleInfo.data.isVinFound) navigate('/vehicle-more-info', { replace: true });
      else {
        actions.analyticsTrackClick({ eventType: 'VIN match is not found' });
        navigate('/vehicle-no-vin', { replace: true });
      }
    }
  }, [actions, vehicleInfo.data, navigate, setIsDisqualified]);

  return [actions.phoenixGetVehicleInfoFromVIN];
};
