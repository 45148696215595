import i18next from '../../utils/i18n';

export interface QualifierOption {
  label: string;
  message?: string;
  tooltip?: string;
  value: string;
}

export const getQualifierOptions = (): Record<string, QualifierOption> => ({
  RideShare50OrMore: {
    label: i18next.t('qualifier.options.RideShare50OrMore.label') as string,
    message: i18next.t('qualifier.options.RideShare50OrMore.message') as string,
    tooltip: i18next.t('qualifier.options.RideShare50OrMore.tooltip') as string,
    value: 'RideShare50OrMore',
  },
  Over150kMiles: {
    label: i18next.t('qualifier.options.Over150kMiles.label') as string,
    message: i18next.t('qualifier.options.Over150kMiles.message') as string,
    tooltip: i18next.t('qualifier.options.Over150kMiles.tooltip') as string,
    value: 'Over150kMiles',
  },
  VehicleIsOver10: {
    label: i18next.t('qualifier.options.VehicleIsOver10.label') as string,
    message: i18next.t('qualifier.options.VehicleIsOver10.message') as string,
    tooltip: i18next.t('qualifier.options.VehicleIsOver10.tooltip') as string,
    value: 'VehicleIsOver10',
  },
  CreditScoreisUnder510: {
    label: i18next.t('qualifier.options.CreditScoreisUnder510.label') as string,
    value: 'CreditScoreisUnder510',
  },
  None: { label: i18next.t('qualifier.options.None.label') as string, value: 'None' },
});

export const getQualifierList = (): QualifierOption[] => {
  const qualifierOptions = getQualifierOptions();
  return [
    qualifierOptions.RideShare50OrMore,
    qualifierOptions.Over150kMiles,
    qualifierOptions.VehicleIsOver10,
    qualifierOptions.CreditScoreisUnder510,
    qualifierOptions.None,
  ];
};
