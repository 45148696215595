import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ReactComponent as CallIcon } from '../../assets/call.svg';
import { ReactComponent as PaymentIcon } from '../../assets/payment.svg';
import { ReactComponent as PhoneIcon } from '../../assets/phone.svg';

import { EstimatedOffer } from '../../components/EstimatedOffer/EstimatedOffer';
import type { EstimatedOfferProps } from '../../components/EstimatedOffer/EstimatedOffer';
import { AppActions } from '../../store/configureStore';
import useEffectOnce from '../../utils/useEffectOnce';

const Title = styled(Typography)(({ theme }) => ({
  height: '36px',
  fontSize: '32px',
  pointerEvents: 'none',
  '& > strong': {
    color: theme.palette.green.main,
    fontStyle: 'italic',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  marginBottom: '24px',
  marginTop: '12px',
}));

const PhoneIconLabelStyled = styled(PhoneIcon)(({ theme }) => ({
  color: theme.palette.teal.main,
  pointerEvents: 'none',
  height: '36px',
  minWidth: '36px',
}));

const PaymentIconLabelStyled = styled(PaymentIcon)(({ theme }) => ({
  color: theme.palette.teal.main,
  pointerEvents: 'none',
  height: '36px',
  minWidth: '36px',
}));

const CallIconLabelStyled = styled(CallIcon)(({ theme }) => ({
  color: theme.palette.teal.main,
  pointerEvents: 'none',
  height: '36px',
  minWidth: '36px',
}));

const Step = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  margin: '10px 0 10px 15px',
}));

const CallUsText = styled.a(props => ({
  color: props.theme.palette.teal.main,
  cursor: 'grab',
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '22px',
  letterSpacing: '0.46px',
  margin: '0 0 2px 15px',
  textDecoration: 'none',
}));

const AgreementText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.main,
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '0.15px',
}));

export type OffRampProps = {
  actions: AppActions;
  estimatedOfferSelected: {
    data?: EstimatedOfferProps;
  };
};

const OffRampResult = ({ actions, estimatedOfferSelected }: OffRampProps) => {
  const { t } = useTranslation();
  const { data } = estimatedOfferSelected;
  const hasEstimatedOffer = !!data;

  useEffectOnce(() => actions.analyticsTrackPage(`OffRamp${hasEstimatedOffer ? 'WithSelectedOffer' : 'WithoutSelectedOffer'}`));

  const onPhoneClick = () =>
    actions.analyticsTrackClick({
      eventType: `OffRamp ${hasEstimatedOffer ? '' : 'Zero Offers'} Phone Number Clicked`,
    });

  return (
    <>
      <Title variant="h1">
        <Trans>{t('offRamp.title')}</Trans>
      </Title>
      <Description variant="body2">
        {hasEstimatedOffer
          ? t('offRamp.descriptionWithOffer')
          : t('offRamp.descriptionWithoutOffer')}
      </Description>
      {hasEstimatedOffer && (
        <List sx={{ padding: '0', marginBottom: '13px' }}>
          <EstimatedOffer offer={data} data-testid="estimatedOfferCard" />
        </List>
      )}
      <Typography variant="h2" sx={{ fontSize: '18px', lineHeight: '24px', margin: '0 0 12px' }}>
        {t('offRamp.nextSteps')}
      </Typography>
      <List sx={{ padding: '0' }}>
        <ListItem sx={{ padding: '0' }}>
          <PhoneIconLabelStyled />
          <Step variant="body2">{t('offRamp.step1')}</Step>
        </ListItem>
        <ListItem sx={{ padding: '0' }}>
          <PaymentIconLabelStyled />
          <Step variant="body2">{t('offRamp.step2')}</Step>
        </ListItem>
      </List>

      <Divider>
        <Typography variant="h2" sx={{ fontSize: '16px', margin: '15px 0' }}>
          {t('offRamp.or')}
        </Typography>
      </Divider>

      <List sx={{ margin: '5px 0 20px', padding: '0' }}>
        <ListItem sx={{ padding: '0' }}>
          <CallIconLabelStyled />
          <Stack spacing={0}>
            <CallUsText
              href={'tel:' + t('contactPhoneNumber').replace(/-/g, '')}
              onClick={onPhoneClick}
            >
              {t('offRamp.callUs')} {t('contactPhoneNumber')}
            </CallUsText>
            <Typography variant="body2" sx={{ fontSize: '16px', marginLeft: '15px' }}>
              {t('offRamp.callUsDescription')}
            </Typography>
          </Stack>
        </ListItem>
      </List>
      {hasEstimatedOffer && (
        <Box sx={{ marginBottom: '15px' }}>
          <AgreementText variant="body2">{t('offRamp.finalNote')}</AgreementText>
        </Box>
      )}
    </>
  );
};

export default OffRampResult;
