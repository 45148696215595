import Logger from '../../libs/Logger';
import { callRecaptcha } from '../../utils/recaptcha';
export const logger = new Logger('Phoenix');

export enum ApiResources {
  contacts = 'contacts',
  updateSSN = 'contacts/update-ssn',
  leads = 'leads',
  qualifiers = 'leads/qualifiers',
  years = 'vehicle/years',
  makes = 'vehicle/makes',
  models = 'vehicle/models',
  vehicle = 'vehicle',
  assets = 'assets',
  asset = 'assets/{assetId}',
  address = 'addresses',
  currentaddress = 'currentaddress',
  estimatedOffers = 'lender',
  estimatedOffer = 'lender/{dealId}/active',
  credits = 'contacts/{contactId}/credit-summary?leadId={leadId}',
  creditScore = 'contacts/{contactId}/credit-score',
  progressHistory = 'progresshistory',
}

export enum Methods {
  get = 'GET',
  patch = 'PATCH',
  post = 'POST',
  put = 'PUT',
}

export const RECAPTCHA_ERROR_MESSAGE = 'Seems like a bot.';

export const getUrl = (resource: ApiResources) =>
  `${process.env.REACT_APP_PHOENIX_URL}/${resource}`;

export const getRequestHeaders = async () => {
  const overrideToken = process.env.REACT_APP_RECAPTCHA_OVERRIDE;
  const authToken: string = overrideToken ? '' : await callRecaptcha();
  return {
    'Content-Type': 'application/json',
    ...(authToken ? { AuthToken: authToken } : {}),
    ...(overrideToken ? { OverrideToken: overrideToken } : {}),
  };
};

export const processResponse = async (response: Response) => {
  const jsonResponse = await response.json();
  if (response.status > 204 && !jsonResponse.message.includes(RECAPTCHA_ERROR_MESSAGE)) {
    return null;
  }
  return jsonResponse;
};

export type VehicleOptionsResponse =
  | { id: number; name: string }[]
  | { message: string; code: string; details: string };

export type AssetResponse =
  | { make: string; model: string; year: number; assetId: string; mileage: number }
  | { message: string; errors: { key: string; value: string }[] };

export type AddressResponse =
  | {
      address: string;
      aptNumber: string;
      city: string;
      state: string;
      zipCode: number;
      moveidDate: string;
      residenceType: string;
      monthlyPayment: string;
    }[]
  | { message: string; code: string; details: string };
