import React from "react";
import { IMaskInput } from "react-imask";

interface PhoneMaskCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PhoneMaskCustom = React.forwardRef<
  HTMLInputElement,
  PhoneMaskCustomProps
>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+1 (000) 000-0000"
      inputRef={ref}
      onAccept={(value: unknown) =>
        onChange({ target: { name: props.name, value: value as string } })
      }
      overwrite
    />
  );
});

export default PhoneMaskCustom;
