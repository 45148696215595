import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import LoanMatchFormContainer from '../../containers/LoanMatchFormContainer';
import Header from '../../components/Header/Header';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';

const VehicleMoreInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('loanMatch.title')}</Typography>
        <LoanMatchFormContainer />
      </ScreenContainer>
      <RecaptchaLogo />
    </>
  );
};

export default VehicleMoreInfo;
