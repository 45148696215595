import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DisqualifiedModalList from '../DisqualifiedModalList/DisqualifiedModalList';
import { getQualifierOptions } from '../../shared/constants/qualifierOptions';
import { LOADING_WAIT_IN_MS } from '../../shared/constants/misc';
import { AppActions } from '../../store/configureStore';

const StyledButton = styled(Button)(props => ({
  'font-size': '14px',
  'font-weight': 600,
  'line-height': '24px',
  'text-transform': 'uppercase',
  'letter-spacing': '0.4px',
  color: props.theme.palette.teal.main,
}));

const DisqualifiedModal = ({
  onClick,
  options,
  returnUrl = '/qualifier',
  onReturn,
  ...props
}: DialogProps & {
  onClick?: () => void;
  options: string[];
  onReturn?: () => void;
  returnUrl?: string;
  actions: AppActions;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const qualifierOptions = getQualifierOptions();

  return (
    <Dialog
      maxWidth="xs"
      PaperProps={{
        sx: {
          maxWidth: 525,
        },
      }}
      {...props}
    >
      <DialogTitle variant="h2">{t('disqualifiedModal.title')}</DialogTitle>
      <DialogContent>
        <DisqualifiedModalList
          options={options.map(option => qualifierOptions[option]?.message || '')}
        />
        <Typography sx={{ fontWeight: 700, mt: 3 }}>{t('disqualifiedModal.subtitle')}</Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton
          onClick={() => {
            props.actions.analyticsTrackClick({
              eventType: 'Refinance Another Vehicle Yes Option Clicked',
            });
            if (onReturn) onReturn();
            setTimeout(() => {
              if (onClick) onClick();
              navigate(returnUrl, { replace: true });
            }, LOADING_WAIT_IN_MS);
          }}
          color="teal"
        >
          {t('disqualifiedModal.confirm')}
        </StyledButton>
        <StyledButton
          onClick={() => {
            props.actions.analyticsTrackClick({
              eventType: 'Refinance Another Vehicle No Option Clicked',
            });
            if (onClick) onClick();
            const allSelected = options.reduce((a, b) => (b ? `${a}|${b}` : `${a}`));
            navigate(`/disqualified?selected=${allSelected}`, { replace: true });
          }}
          color="teal"
        >
          {t('disqualifiedModal.cancel')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default DisqualifiedModal;
