import Header from '../../components/Header/Header';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import FailRecaptchaContainer from '../../containers/FailRecaptchaContainer';

const FailRecaptcha = () => {
  return (
    <>
      <Header />
      <ScreenContainer>
        <FailRecaptchaContainer />
      </ScreenContainer>
    </>
  );
};

export default FailRecaptcha;
