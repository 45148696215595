import { getUrl, getRequestHeaders, logger, ApiResources, Methods, processResponse } from './utils';

export type ContactsData = {
  firstName: '';
  lastName: '';
  middleName: '';
  suffix: '';
  address: '';
  aptNumber: '';
  zipCode: '';
  city: '';
  state: '';
  email: '';
  phoneNumber: '';
  agreement: false;
  metadata?: { [key: string]: string };
};

export type ContactsResponse =
  | { contactId: string; leadId: string }
  | { message: string; code: string; details: string };

const mapContactsPayload = (payload: ContactsData) => ({
  firstName: payload.firstName,
  lastName: payload.lastName,
  middleInitial: payload.middleName,
  suffix: payload.suffix,
  email: payload.email,
  // eslint-disable-next-line no-useless-escape
  cellPhone: payload.phoneNumber.replace(/\+1|[() \-]/g, ''),
  addresses: [
    {
      streetLine1: payload.address,
      streetLine2: payload.aptNumber,
      zip: payload.zipCode,
      city: payload.city,
      state: payload.state,
    },
  ],
  isConsentSelected: payload.agreement,
  metadata: payload.metadata,
});

export const postContact = async (payload: ContactsData): Promise<ContactsResponse | null> => {
  const endpoint = getUrl(ApiResources.contacts);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(mapContactsPayload(payload)),
  };

  logger.log('postContacts request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    return processResponse(response);
  } catch (err) {
    logger.error('postContacts error', err);
    return null;
  }
};
