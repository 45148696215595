import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import loanInfoSchema from '../../schemas/LoanInfo';
import Logger from '../../libs/Logger';
import CurrencyTextField from '../CurrencyTextField/CurrencyTextField';
import ContainerFooter from '../ContainerFooter/ContainerFooter';
import CustomButton from '../CustomButton/CustomButton';

import { AppActions } from '../../store/configureStore';
import { onInputCurrency, onKeyDownCurrency } from '../../utils/input';
import { useGetEstimatedOffers } from '../../utils/useGetEstimatedOffers';
import useEffectOnce from '../../utils/useEffectOnce';

export type Props = {
  actions: AppActions;
  estimatedOffers: {
    data?: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    }[];
    errors?: { [key: string]: string };
  };
  selectedAsset: {
    data?: {
      lienHolderId: string;
      loanInstitution: string;
      currentMonthlyPayment: number;
      estimatedLoanBalance: number;
      remainingTerm: number;
    }[];
    errors?: { [key: string]: string };
  };
};

const logger = new Logger('LoanInfoForm');

const initialValues = {
  loanInstitution: '',
  currentMonthlyPayment: '',
  estimatedLoanBalance: '',
};

const LoanInfoForm = ({ actions, estimatedOffers, selectedAsset }: Props) => {
  const { t } = useTranslation();
  const [getEstimatedOffers] = useGetEstimatedOffers({ actions, estimatedOffers });
  const [getOffers, setGetOffers] = useState<boolean>(false);

  useEffectOnce(() => actions.analyticsTrackPage('LoanInfo'));

  useEffect(() => {
    if (selectedAsset.data && !getOffers) {
      setGetOffers(true);
      getEstimatedOffers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset.data]);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: loanInfoSchema(),
      onSubmit: values => {
        const parsedValues = {
          currentMonthlyPayment: parseFloat(values.currentMonthlyPayment.replaceAll(',', '')),
          estimatedLoanBalance: parseFloat(values.estimatedLoanBalance.replaceAll(',', '')),
          loanInstitution: values.loanInstitution,
        };
        logger.log(parsedValues);
        actions.phoenixPostAsset({ ...parsedValues, isLoanManuallyEntered: true });
      },
      validateOnMount: true,
    });

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1}>
        <TextField
          id="loanInstitution"
          name="loanInstitution"
          label={t('loanInfo.form.institution')}
          variant="filled"
          value={values.loanInstitution}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.loanInstitution && !!errors.loanInstitution}
          helperText={touched.loanInstitution && errors.loanInstitution}
          InputProps={{ sx: { bgcolor: 'lighterGray.main' } }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <CurrencyTextField
          id="currentMonthlyPayment"
          name="currentMonthlyPayment"
          label={t('loanInfo.form.monthlyPayment')}
          variant="filled"
          value={values.currentMonthlyPayment}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDownCurrency}
          onInput={onInputCurrency}
          error={touched.currentMonthlyPayment && !!errors.currentMonthlyPayment}
          helperText={touched.currentMonthlyPayment && errors.currentMonthlyPayment}
          InputProps={{ sx: { bgcolor: 'lighterGray.main' } }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <CurrencyTextField
          id="estimatedLoanBalance"
          name="estimatedLoanBalance"
          label={t('loanInfo.form.loanBalance')}
          variant="filled"
          value={values.estimatedLoanBalance}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDownCurrency}
          onInput={onInputCurrency}
          error={touched.estimatedLoanBalance && !!errors.estimatedLoanBalance}
          helperText={touched.estimatedLoanBalance && errors.estimatedLoanBalance}
          InputProps={{ sx: { bgcolor: 'lighterGray.main' } }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
      </Stack>
      <ContainerFooter>
        <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
          {t('loanInfo.form.continue')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default LoanInfoForm;
