import styled from 'styled-components';
import { Theme, useTheme } from '@mui/material/styles';

const Step = styled.div<{ selected: boolean; theme: Theme }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.green.main : theme.palette.neutral.main};
  height: 4px;
  max-width: 45px;
  margin: 0 5px;
  width: 25%;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 32px;
`;

const StepProgress: React.FC<{ size: number; selected?: number }> = ({ size, selected }) => {
  const theme = useTheme();
  return (
    <StepsContainer>
      {Array(size)
        .fill(null)
        .map((_, index) => index)
        .map(index => (
          <Step
            key={index}
            selected={selected === index}
            theme={theme}
            data-testid="progress-step"
          />
        ))}
    </StepsContainer>
  );
};

export default StepProgress;
