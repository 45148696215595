import { useState } from 'react';
import styled from 'styled-components';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { ReactComponent as CurrencyIcon } from '../../assets/currency-usd.svg';
import { ReactComponent as CurrencyRedIcon } from '../../assets/currency-usd_red.svg';
import InputAdornment from '@mui/material/InputAdornment';

const CurrencyIconLabelStlyed = styled(CurrencyIcon)(props => ({
  color: props.theme.palette.teal.main,
  margin: '0 -4px -3px auto',
  pointerEvents: 'none',
  height: '100%',
}));

const CurrencyIconLabelRedStlyed = styled(CurrencyRedIcon)(props => ({
  color: props.theme.palette.teal.main,
  margin: '0 -4px -3px auto',
  pointerEvents: 'none',
  height: '100%',
}));

const CurrencyIconStyled = styled(CurrencyIcon)(props => ({
  color: props.theme.palette.teal.main,
  display: 'block',
  margin: '2px auto 0 -4px',
  pointerEvents: 'none',
  width: '18px',
}));

const CurrencyTextField = (props: TextFieldProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <TextField
      id="phoneNumber"
      name="phoneNumber"
      variant="filled"
      {...props}
      label={
        <>
          {!isFocused &&
            !props.value &&
            (props.error ? <CurrencyIconLabelRedStlyed /> : <CurrencyIconLabelStlyed />)}{' '}
          {props.label}
        </>
      }
      InputProps={{
        ...props.InputProps,
        ...(isFocused || !!props.value
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyIconStyled />
                </InputAdornment>
              ),
            }
          : {}),
      }}
      onFocus={e => {
        setIsFocused(true);
        if (props.onFocus) props.onFocus(e);
      }}
      onBlur={e => {
        setIsFocused(false);
        if (props.onBlur) props.onBlur(e);
      }}
    />
  );
};

export default CurrencyTextField;
