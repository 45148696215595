import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import ActiveLead, { Props } from '../screens/ActiveLead/ActiveLead';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import type { OffRampProps } from '../components/OffRampResult/OffRampResult';

const ActiveLeadContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    contacts: phoenixRoot.contacts as Props['contacts'],
    progress: phoenixRoot.progress as Props['progress'],
    creditSummary: phoenixRoot.creditSummary as Props['creditSummary'],
    estimatedOffers: phoenixRoot.estimatedOffers as Props['estimatedOffers'],
    estimatedOfferSelected: phoenixRoot.estimatedOfferSelected as OffRampProps['estimatedOfferSelected'],
    vehicleInfo: phoenixRoot.vehicleInfo as Props['vehicleInfo'],
    actions,
  };

  return <ActiveLead {...props} />;
};

export default ActiveLeadContainer;
