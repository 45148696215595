import { getUrl, getRequestHeaders, logger, ApiResources, Methods } from './utils';

export type VehicleInfo =
  | {
      assetId: string;
      isVehicle10YearsOrOlder: boolean;
      isVinFound: boolean;
      make: string;
      message: string;
      model: string;
      year: number;
    }
  | { message: string; code: string; details: string };

export const getVehicleInfoFromVIN = async ({
  vin,
  contactId,
  leadId,
}: {
  vin: string;
  contactId: string;
  leadId: string;
}): Promise<null> => {
  const endpoint = `${getUrl(ApiResources.vehicle)}/${vin}?contactId=${contactId}&leadId=${leadId}`;
  const request = {
    method: Methods.get,
    headers: await getRequestHeaders(),
  };

  logger.log('getVehicleInfoFromVIN request', endpoint, request);
  try {
    const jsonResponse = await fetch(endpoint, request);
    const response = await jsonResponse.json();

    return response;
  } catch (err) {
    logger.error('getVehicleInfoFromVIN error', err);
    return null;
  }
};
