import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const MainContainer = styled.div`
  height: 60px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
`;

const StyledBox = styled(Box)<{ theme: Theme; $isOpen: boolean }>`
  background-color: ${({ theme }) => theme.palette.white.main};
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.neutral.main};
  border-radius: 2px;
  box-shadow: ${({ theme }) => theme.palette.gray.main} 0 0 5px;
  display: flex;
  height: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  right: 0;
  transition: width 0.3s ease 0s;
  width: ${({ $isOpen }) => ($isOpen ? '256px' : '70px')};

  &:hover {
    cursor: pointer;
  }
`;

const StyledLogo = styled.div`
  background: url(https://www.gstatic.com/recaptcha/api2/logo_48.png);
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  height: 100%;
  margin-right: auto;
  width: 70px;
`;

const StyledTextContainer = styled.div<{ theme: Theme }>`
  background-color: ${({ theme }) => theme.palette.blue.main};
  color: ${({ theme }) => theme.palette.white.main};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 13px;
  line-height: 20px;
  justify-content: center;
  margin-right: auto;
  padding: 10px;
  width: 186px;

  & a {
    color: ${({ theme }) => theme.palette.white.main};
    font-size: 10px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const RecaptchaLogo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <MainContainer>
      <StyledBox $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <StyledLogo />
        <StyledTextContainer>
          <span>
            {t('recaptcha.protectedBy')} <b>{t('recaptcha.recaptcha')}</b>
          </span>
          <span>
            <a
              href="https://www.google.com/intl/en/policies/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              {t('recaptcha.privacy')}
            </a>{' '}
            -{' '}
            <a
              href="https://www.google.com/intl/en/policies/terms/"
              target="_blank"
              rel="noreferrer"
            >
              {t('recaptcha.terms')}
            </a>
          </span>
        </StyledTextContainer>
      </StyledBox>
    </MainContainer>
  );
};

export default RecaptchaLogo;
