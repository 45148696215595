import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import LoanInfoFormContainer from '../../containers/LoanInfoFormContainer';
import Header from '../../components/Header/Header';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';

const LoanInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('loanInfo.title')}</Typography>
        <p></p>
        <LoanInfoFormContainer />
      </ScreenContainer>
      <RecaptchaLogo />
    </>
  );
};

export default LoanInfo;
