import { useCallback, useEffect } from 'react';
import { AppActions } from '../store/configureStore';
import { getQualifierOptions } from '../shared/constants/qualifierOptions';
import { useNavigate } from 'react-router';

type Props = {
  actions: AppActions;
  creditSummary: {
    ficoDetails?: { name: string; scoreType: string; score: string }[];
    loanDetails?: {
      lender: string;
      currentBalance: string;
      openDate: string;
      payment: string;
      remainingTerm: string;
    }[];
    errors?: {
      message: string;
      code: string;
      details: string;
    };
  };
};

export const useGetCreditSummary = ({ actions, creditSummary }: Props) => {
  const navigate = useNavigate();
  const qualifierOptions = getQualifierOptions();

  const processDetails = useCallback(
    (
      ficoDetails: NonNullable<Props['creditSummary']['ficoDetails']>,
      loanDetails: NonNullable<Props['creditSummary']['loanDetails']>
    ) => {
      if (ficoDetails.filter(f => parseInt(f.score) < 510 && f.scoreType === 'ExpV9').length) {
        actions.phoenixPostQualifiers([qualifierOptions.CreditScoreisUnder510.value]);
        navigate('/disqualified-credit', { replace: true });
      } else if (!ficoDetails.find(f => f.scoreType === 'ExpV9')) {
        navigate('/credit-score', { replace: true });
      } else if (!ficoDetails.find(f => parseInt(f.score) >= 510) && !loanDetails.length) {
        navigate('/loan-info', { replace: true });
      } else if (
        !loanDetails.length ||
        !loanDetails.find(
          loan =>
            loan.lender &&
            loan.openDate &&
            loan.payment &&
            loan.currentBalance &&
            loan.remainingTerm
        )
      ) {
        navigate('/loan-info', { replace: true });
      } else {
        navigate('/loan-match', { replace: true });
      }
    },
    [qualifierOptions, actions, navigate]
  );

  useEffect(() => {
    const { ficoDetails, loanDetails, errors } = creditSummary;

    if (ficoDetails && loanDetails) {
      if (ficoDetails.length) {
        processDetails(ficoDetails, loanDetails);
      } else {
        navigate('/credit-score', { replace: true });
      }
    } else if (errors) navigate('/credit-score', { replace: true });
  }, [creditSummary, navigate, processDetails]);

  return [actions.phoenixGetCreditSummary];
};
