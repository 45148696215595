import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import EstimatedOffersForm, { Props } from '../components/EstimatedOffersForm/EstimatedOffersForm';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const EstimatedOffersFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    actions,
    estimatedOffers: phoenixRoot.estimatedOffers as Props['estimatedOffers'],
    estimatedOfferSelected: phoenixRoot.estimatedOfferSelected as Props['estimatedOfferSelected'],
  };

  return <EstimatedOffersForm {...props} />;
};

export default EstimatedOffersFormContainer;
