import { useMemo } from 'react';
import styled from 'styled-components';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AboutYou from '../AboutYou/AboutYou';
import ActiveLead from '../../containers/ActiveLeadContainer';
import Address from '../Address/Address';
import CurrentAddress from '../Address/CurrentAddress';
import AddressHistory from '../Address/AddressHistory';
import Disqualified from '../Disqualified/Disqualified';
import DisqualifiedCredit from '../DisqualifiedCredit/DisqualifiedCredit';
import ErrorContainer from '../../containers/ErrorContainer';
import EstimatedOffers from '../EstimatedOffers/EstimatedOffers';
import Qualifier from '../Qualifier/Qualifier';
import EstimatedCreditScore from '../EstimatedCreditScore/EstimatedCreditScore';
import FailRecaptcha from '../FailRecaptcha/FailRecaptcha';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import LoanMatch from '../LoanMatch/LoanMatch';
import LoanInfo from '../LoanInfo/LoanInfo';
import OffRamp from '../OffRamp/OffRamp';
import RequestSSN from '../RequestSSN/RequestSSN';

import VehicleInfo from '../VehicleInfo/VehicleInfo';
import VehicleMoreInfo from '../VehicleMoreInfo/VehicleMoreInfo';
import VehicleNoVINInfo from '../../containers/VehicleNoVINInfoContainer';

import { useAlertWhenUserLeaves } from '../../utils/useAlertWhenUserLeaves';

import theme from '../../theme';
import './Main.css';

export interface Props {
  isLoading: boolean;
  loadingImage: string;
  error?: { message: ''; text: ''; source: '' };
}

const MainContainer = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'flex')};
  flex-direction: column;
  ${theme.breakpoints.down('sm')} {
    min-height: 100vh;
  }
`;

const Main = ({ isLoading, loadingImage, error }: Props) => {
  const isRecaptchaError = useMemo(() => (error?.message || '').includes('bot'), [error]);

  useAlertWhenUserLeaves();

  return (
    <ThemeProvider theme={theme}>
      {isLoading && !error && <LoadingModal loadingImage={loadingImage} />}
      {!isLoading && !!error && !isRecaptchaError && <ErrorContainer />}
      {!isLoading && !!error && isRecaptchaError && <FailRecaptcha />}
      {/* hide dialogs when loading */}
      <GlobalStyles
        styles={{ '.MuiDialog-root': { display: isLoading || !!error ? 'none' : 'block' } }}
      />
      <MainContainer isLoading={isLoading || !!error}>
        <Router>
          <Routes>
            <Route path="/" element={<AboutYou />} />
            <Route path="qualifier" element={<Qualifier />} />
            <Route path="credit-score" element={<EstimatedCreditScore />} />
            <Route path="disqualified" element={<Disqualified />} />
            <Route path="disqualified-credit" element={<DisqualifiedCredit />} />
            <Route path="vehicle-info" element={<VehicleInfo />} />
            <Route path="vehicle-more-info" element={<VehicleMoreInfo />} />
            <Route path="vehicle-no-vin" element={<VehicleNoVINInfo />} />
            <Route path="loan-match" element={<LoanMatch />} />
            <Route path="active-lead" element={<ActiveLead />} />
            <Route path="estimated-offers" element={<EstimatedOffers />} />
            <Route path="address" element={<Address />} />
            <Route path="current-address" element={<CurrentAddress />} />
            <Route path="address-history" element={<AddressHistory />} />
            <Route path="off-ramp" element={<OffRamp />} />
            <Route path="next-steps" element={<FailRecaptcha />} />
            <Route path="loan-info" element={<LoanInfo />} />
            <Route path="request-ssn" element={<RequestSSN />} />
          </Routes>
        </Router>
      </MainContainer>
    </ThemeProvider>
  );
};

export default Main;
