import { ElementType, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { InputBaseComponentProps } from '@mui/material/InputBase';

import SSNMaskCustom from './SSNMaskInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const PlaceholderContainer = styled.div`
  display: block;
  background-color: #f4f6f8;
  box-sizing: border-box;
  height: 56px;
  left: 0;
  padding: 24px 12px 8px 12px;
  position: absolute;
  top: 0;
  width: 100%;
`;

const FormatPlaceholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.main,
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '0.15px',
  textAlign: 'right',
  width: '105px',
  zIndex: 0
}));

const SSNField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    letterSpacing: '1.15px',
    zIndex: 1
  }
}));

const SSNTextField = (props: TextFieldProps) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>('');
  const inputValue = props.value;

  useEffect(() => {
    const formatLength = 9;
    let placeholderInitVal;
    let placeholderLength = 0;
    let result = '';
  
    if (typeof inputValue === 'string') {
      placeholderInitVal = '#'.repeat(formatLength - inputValue.length);
      placeholderLength = placeholderInitVal.length;
      result = placeholderInitVal.slice(-4);
  
      if (placeholderLength >= 4) {
        result = `-${result}`;
  
        if (placeholderLength >= 5) {
          result = `${placeholderInitVal.slice(-6, -4)}${result}`;
  
          if (placeholderLength >= 6) {
            result = `${placeholderInitVal.slice(-9, -6)}-${result}`;
          }
        }
      }
  
    } else {
      result = '#'.repeat(formatLength);
    }

    setPlaceholder(result);
  }, [inputValue, setPlaceholder]);

  return (
    <Container>
      <>
        <SSNField
          id='ssn'
          name='ssn'
          variant='filled' 
          label={t('requestSSN.label')}
          {...props}
          InputProps={{
            inputComponent: SSNMaskCustom as unknown as ElementType<InputBaseComponentProps>,
            ...props.InputProps,
          }}
          onFocus={e => {
            setIsFocused(true);
            if (props.onFocus) props.onFocus(e);
          }}
          onBlur={e => {
            setIsFocused(false);
            if (props.onBlur) props.onBlur(e);
          }}
          autoComplete='off'
        />
        <PlaceholderContainer>
          <>
            {(isFocused || inputValue) && <FormatPlaceholder>{placeholder}</FormatPlaceholder>}
          </>
        </PlaceholderContainer>
      </>
    </Container>
  );
};

export default SSNTextField;
