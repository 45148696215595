import { ReactNode } from 'react';
import styled from 'styled-components';
import { useMediaQuery, useTheme } from '@mui/material';
import Container from '@mui/material/Container';

type Props = {
  children: ReactNode;
};

const ContainerStyled = styled(Container)<{ $isSmall: boolean }>`
  box-shadow: ${({ theme, $isSmall }) => theme.shadows[$isSmall ? 0 : 1]};
  background-color: ${({ theme }) => theme.palette.white.main};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme }) => `${theme.spacing(3.5)} ${theme.spacing(2)} ${theme.spacing(2)}`};
  min-height: 500px;

  & form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
`;

const ScreenContainer = ({ children }: Props) => {
  const theme = useTheme();
  const isSmall = !useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <ContainerStyled $isSmall={isSmall} maxWidth="sm">
      {children}
    </ContainerStyled>
  );
};

export default ScreenContainer;
