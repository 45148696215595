import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import RequestSSNForm, { Props } from '../components/RequestSSNForm/RequestSSNForm';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const RequestSSNFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    actions,
    ssn: phoenixRoot.ssn as Props['ssn'],
    contacts: phoenixRoot.contacts as Props['contacts'],
    creditSummary: phoenixRoot.creditSummary as Props['creditSummary'],
  };

  return <RequestSSNForm {...props} />;
};

export default RequestSSNFormContainer;
