import styled from 'styled-components';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import Header from '../../components/Header/Header';
import kovo from '../../assets/kovo.png';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import { useTranslation } from 'react-i18next';

import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/actions';
import { useAppDispatch } from '../../store/hooks';
import { AppActions } from '../../store/configureStore';
import useEffectOnce from '../../utils/useEffectOnce';

const PartnerContainer = styled.div`
  display: grid;
  grid-template-columns: 115px 1fr;
`;

const KovoLogo = styled.img`
  height: auto;
  max-height: 100%;
  max-width: 76px;
  pointer-events: none;
  width: auto;
`;

const KovoLogoContainer = styled(Container)`
  align-items: center;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.yellow.main};
  display: flex;
  height: 88px;
  justify-content: center;
  margin: ${({ theme }) => `${theme.spacing(2.5)} 0 ${theme.spacing(2)}`};
  width: 97px;
`;

const LearnMoreLink = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing(15)};
  text-decoration: none;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: auto;
  }
`;

const PhoneNumber = styled.a`
  cursor: grab;
  text-decoration: none;
`;

const DisqualifiedCredit = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const actions: AppActions = bindActionCreators(Actions, dispatch);

  useEffectOnce(() => actions.analyticsTrackPage('DisqualifiedCredit'));

  const onPhoneClick = () =>
    actions.analyticsTrackClick({ eventType: 'Credit Soft Decline Phone Number Clicked' });

  const onLearnMoreClick = () =>
    actions.analyticsTrackClick({ eventType: 'Credit Soft Decline Learn More Clicked' });

  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('disqualifiedCredit.title')}</Typography>
        <p>{t('disqualifiedCredit.subtitle')}</p>
        <Typography variant="h3" color="green.main" sx={{ mt: 1 }}>
          {t('disqualifiedCredit.goodNews')}
        </Typography>
        <PartnerContainer>
          <KovoLogoContainer>
            <KovoLogo src={kovo} alt="kovo" />
          </KovoLogoContainer>
          <p>{t('disqualifiedCredit.kovo')}</p>
        </PartnerContainer>
        <p>
          {t('disqualifiedCredit.startText')}
          <PhoneNumber
            href={'tel:' + t('contactPhoneNumber').replace(/-/g, '')}
            onClick={onPhoneClick}
          >
            {t('contactPhoneNumber')}
          </PhoneNumber>
          {t('disqualifiedCredit.endText')}
        </p>
        <LearnMoreLink
          target="_blank"
          href="https://kovocredit.com/collaborations/ilending?irclickid=TocRM1wkUxyIRaC2wsVN7QDqUkDR:JSlxRAkz80&irgwc=1&utm_medium=email&_hsmi=2&_hsenc=p2ANqtz-8zwdHmicRbd63LPsIvvh_G8YTnPzdq0R3TAp3cgRhsi0dzAKNzfQIEUhRuyMoAC52s32vyKCJAEJFwpfpLCqBfjBLYkow_KcIPsjBklIodDnBqF08&utm_content=2&utm_source=hs_email"
          onClick={onLearnMoreClick}
        >
          <Button variant="contained" color="yellow" fullWidth>
            {t('disqualifiedCredit.learnMore')}
          </Button>
        </LearnMoreLink>
      </ScreenContainer>
    </>
  );
};

export default DisqualifiedCredit;
