import * as ms from '../store/reducers/index';
import { useAppSelector } from '../store/hooks';
import Main, { Props } from '../screens/Main/Main';
import { useShowLoadingAnimation } from '../utils/useShowLoadingAnimation';

const MainContainer = () => {
  const appRoot = useAppSelector(ms.appSelectors.appRoot);
  const appRootUi = appRoot.ui as {
    isLoading: boolean;
    loadingImage: string;
  };
  const appRootError = appRoot.error as {
    message: '';
    text: '';
    source: '';
  };

  const isLoading = useShowLoadingAnimation(appRootUi.isLoading);
  const loadingImage = appRootUi.loadingImage;

  const props: Props = {
    isLoading,
    loadingImage,
    error: appRootError,
  };

  return <Main {...props} />;
};

export default MainContainer;
