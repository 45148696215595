import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { ReactComponent as SSNCheckIcon } from '../../assets/ssn-check.svg';

import RequestSSNFormContainer from '../../containers/RequestSSNFormContainer';
import Header from '../../components/Header/Header';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';

const SSNCheckIconStyled = styled(SSNCheckIcon)(({ theme }) => ({
  height: '18px',
  marginTop: '2px',
  minWidth: '15px',
}));

const FinalNoteText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.main,
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '0.15px',
  marginBottom: '18px',
  marginTop: '8px',
  textAlign: 'center',
}));

const RequestSSN = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('requestSSN.title')}</Typography>
        <Typography variant="body2" sx={{ marginTop: '12px', marginBottom: '24px' }}>
          {t('requestSSN.description')}
        </Typography>
        <RequestSSNFormContainer />
        <Divider>
          <SSNCheckIconStyled />
        </Divider>
        <FinalNoteText variant="body2">{t('requestSSN.finalNote')}</FinalNoteText>
        <Divider />
      </ScreenContainer>
      <RecaptchaLogo />
    </>
  );
};

export default RequestSSN;
