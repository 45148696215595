import {
  getUrl,
  getRequestHeaders,
  logger,
  ApiResources,
  Methods,
  AssetResponse,
  processResponse,
} from './utils';

import { get } from 'lodash';

export type AssetDataPost = {
  assetId: string;
  contactId: string;
  leadId: string;
  lender: string;
  payment: number;
  currentBalance: number;
  remainingTerm: number;
  isLoanManuallyEntered: boolean;
};

const mapAssetPayload = (payload: AssetDataPost) => ({
  assetId: payload.assetId,
  contactId: payload.contactId,
  leadId: payload.leadId,
  loanInstitution: get(payload, 'lender') || get(payload, 'loanInstitution'),
  currentMonthlyPayment: get(payload, 'payment') || get(payload, 'currentMonthlyPayment'),
  estimatedLoanBalance: get(payload, 'currentBalance') || get(payload, 'estimatedLoanBalance'),
  ...(payload.remainingTerm ? { remainingTerm: payload.remainingTerm } : null),
  isLoanManuallyEntered: payload.isLoanManuallyEntered,
});

export const postAsset = async (payload: AssetDataPost): Promise<AssetResponse | null> => {
  const endpoint = getUrl(ApiResources.asset).replace('{assetId}', payload.assetId);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(mapAssetPayload(payload)),
  };

  logger.log('postAsset request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    return processResponse(response);
  } catch (err) {
    logger.error('postAsset error', err);
    return null;
  }
};
