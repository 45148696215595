import * as Yup from 'yup';

const schema = () =>
  Yup.object().shape({
    checkboxCondition0: Yup.string(),
    checkboxCondition1: Yup.string(),
    checkboxCondition2: Yup.string(),
    checkboxCondition3: Yup.string(),
  });

export default schema;
