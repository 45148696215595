import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import LoanInfoForm, { Props } from '../components/LoanInfoForm/LoanInfoForm';
import { useAppSelector, useAppDispatch } from '../store/hooks';

const LoanInfoFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    actions,
    estimatedOffers: phoenixRoot.estimatedOffers as Props['estimatedOffers'],
    selectedAsset: phoenixRoot.selectedAsset as Props['selectedAsset'],
  };

  return <LoanInfoForm {...props} />;
};

export default LoanInfoFormContainer;
