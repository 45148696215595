import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import SSNTextField from '../SSNTextField/SSNTextField';
import ContainerFooter from '../ContainerFooter/ContainerFooter';
import { AppActions } from '../../store/configureStore';
import requestSSNSchema from '../../schemas/RequestSSN';
import CustomButton from '../CustomButton/CustomButton';
import { useGetCreditSummary } from '../../utils/useGetCreditSummary';
import useEffectOnce from '../../utils/useEffectOnce';

type Contacts = {
  results: {
    contactId: string;
  };
};

export type Props = {
  actions: AppActions;
  ssn: {
    data?: {
      success: boolean;
      message: string;
    };
    errors?: { [key: string]: string };
  };
  contacts: Contacts;
  creditSummary: {
    ficoDetails?: { name: string; scoreType: string; score: string }[];
    loanDetails?: {
      lender: string;
      currentBalance: string;
      openDate: string;
      payment: string;
      remainingTerm: string;
    }[];
  };
};

const initialValues = {
  agreement: false,
  ssn: '',
};

const AgreementText = styled.span(props => ({
  color: props.theme.palette.gray.main,
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '150%',
  letterSpacing: '0.15px',
}));

const AgreementCheckbox = styled(Checkbox)(props => ({
  alignSelf: 'start',
  marginTop: -1,
  '&.Mui-checked': { color: props.theme.palette.green.main },
  paddingTop: '3px',
}));

const AgreementInfoIcon = styled(InfoIcon)(props => ({
  color: props.theme.palette.teal.main,
  fontSize: 14,
  marginLeft: '-6px',
  lineHeight: '20px',
}));

const RequestSSNForm = ({ actions, ssn, contacts, creditSummary }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  useGetCreditSummary({ actions, creditSummary });

  useEffectOnce(() => actions.analyticsTrackPage('RequestSSN'));

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: requestSSNSchema(),
      onSubmit: values => {
        actions.analyticsTrackFormSubmit({ eventType: 'UpdateSSN' });
        actions.phoenixUpdateSSN({
          contactId: contacts.results.contactId,
          ssn: values.ssn,
        });
      },
      validateOnMount: true,
    });

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1}>
        <SSNTextField
          value={values.ssn}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.ssn && !!errors.ssn}
          helperText={touched.ssn && errors.ssn}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
      </Stack>
      <ContainerFooter>
        <FormControl
          error={!!errors.agreement}
          component="fieldset"
          sx={{ mt: 3 }}
          variant="standard"
        >
          <FormControlLabel
            control={
              <AgreementCheckbox
                id="agreement"
                name="agreement"
                checked={values.agreement}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            label={
              <AgreementText theme={theme}>
                {t('requestSSN.agreement')}
                <Tooltip arrow title={t('requestSSN.agreementTooltip')}>
                  <IconButton>
                    <AgreementInfoIcon />
                  </IconButton>
                </Tooltip>
              </AgreementText>
            }
          />
          <FormHelperText>{errors.agreement}</FormHelperText>
        </FormControl>
        <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
          {t('requestSSN.continue')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default RequestSSNForm;
