import {
  getUrl,
  getRequestHeaders,
  logger,
  ApiResources,
  Methods,
  AssetResponse,
  processResponse,
} from './utils';

export type AssetsDataPost = {
  estimatedMileage: string;
  make: string;
  model: string;
  year: string;
  leadId: string;
  contactId: string;
};

export const postAssets = async (payload: AssetsDataPost): Promise<AssetResponse | null> => {
  const endpoint = getUrl(ApiResources.assets);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(payload),
  };

  logger.log('postAssets request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    return processResponse(response);
  } catch (err) {
    logger.error('postAssets error', err);
    return null;
  }
};
