import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import VehicleMoreInfoForm, { Props } from '../components/VehicleMoreInfoForm/VehicleMoreInfoForm';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const VehicleMoreInfoFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    actions,
    vehicleInfo: phoenixRoot.vehicleInfo as Props['vehicleInfo'],
    progress: phoenixRoot.progress as Props['progress'],
  };

  return <VehicleMoreInfoForm {...props} />;
};

export default VehicleMoreInfoFormContainer;
