import { getUrl, getRequestHeaders, logger, ApiResources, Methods, processResponse } from './utils';

export type SSNData = {
  contactId: string;
  ssn: string;
};

export type SSNResponse =
  | { success: boolean; message: string }
  | { message: string; errors: { key: string; value: string }[] };

const mapSSNPayload = (payload: SSNData) => ({
  contactId: payload.contactId,
  ssn: payload.ssn,
});

export const updateSSN = async (payload: SSNData): Promise<SSNResponse | null> => {
  const endpoint = getUrl(ApiResources.updateSSN);
  const request = {
    method: Methods.patch,
    headers: await getRequestHeaders(),
    body: JSON.stringify(mapSSNPayload(payload)),
  };

  logger.log('updateSSN request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    return processResponse(response);
  } catch (err) {
    logger.error('updateSSN error', err);
    return null;
  }
};
