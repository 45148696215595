import { takeLatest, all, call } from 'redux-saga/effects';
import i18n from 'i18next';

import ActionTypes from '../actions/actionTypes';

import Logger from '../libs/Logger';
import { PayloadAction } from '../store/configureStore';

const logger = new Logger('analyticsSaga');

type trackingAction = PayloadAction<{
  eventType: string;
  parameters?: { [key: string]: string };
}>;

function* trackClick({ payload }: trackingAction) {
  const { eventType, ...rest } = payload;
  try {
    yield call(window.analytics.track, eventType, rest);
    logger.log('Analytics trackClick -> ', eventType, rest);
  } catch (err) {
    logger.log('Analytics trackClick error -> ', eventType, rest);
  }
}

function* trackSystemError({ payload }: trackingAction) {
  const { eventType = 'System Error', ...rest } = payload;
  try {
    yield call(window.analytics.track, eventType, rest);
    logger.log('Analytics trackSystemError -> ', eventType, rest);
  } catch (err) {
    logger.log('Analytics trackSystemError error -> ', eventType, rest);
  }
}

function* trackFormSubmit({ payload }: trackingAction) {
  const { eventType, ...rest } = payload;
  try {
    yield call(window.analytics.track, eventType, rest);
    logger.log('Analytics trackFormSubmit -> ', eventType, rest);
  } catch (err) {
    logger.log('Analytics trackFormSubmit error -> ', eventType, rest);
  }
}

function* trackPage({ payload: pageName }: PayloadAction<string>) {
  const pageNameTranslation = `pageTitles.${pageName}`;
  document.title = `${i18n.t(pageNameTranslation)}${i18n.t('pageTitles.suffix')}`;
  try {
    yield call(window.analytics.page, pageName);
    logger.log('Analytics trackClick -> ', pageName);
  } catch (err) {
    logger.log('Analytics trackClick error -> ', pageName);
  }
}

function* phoenixSaga() {
  yield all([takeLatest(ActionTypes.ANALYTICS_TRACK_CLICK, trackClick)]);
  yield all([takeLatest(ActionTypes.ANALYTICS_TRACK_FORM_SUBMIT, trackFormSubmit)]);
  yield all([takeLatest(ActionTypes.ANALYTICS_TRACK_PAGE, trackPage)]);
  yield all([takeLatest(ActionTypes.ANALYTICS_TRACK_SYSTEM_ERROR, trackSystemError)]);
}

export default phoenixSaga;
