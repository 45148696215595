import * as Yup from 'yup';
import i18next from '../utils/i18n';

const schema = () =>
  Yup.object().shape({
    loanInstitution: Yup.string().required(i18next.t('loanInfo.schema.institution') as string),
    currentMonthlyPayment: Yup.string().required(
      i18next.t('loanInfo.schema.monthlyPayment') as string
    ),
    estimatedLoanBalance: Yup.string().required(i18next.t('loanInfo.schema.loanBalance') as string),
  });

export default schema;
