import { getUrl, getRequestHeaders, logger, ApiResources, Methods, processResponse } from './utils';

export type EstimatedOfferData = {
  contactId: string;
  dealId: string;
  rate: number;
  monthlyPaymentAmount: number;
  term: number;
  offerType: string;
};

export type PostEstimatedOffersRequestData = {
  contactId: string;
  dealId: string;
};

export type PostEstimatedOfferResponse =
  | {
      success: boolean;
      message: string;
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    }
  | { message: string; code: string; details: string; errors: { key: string; value: string }[] };

export const postEstimatedOffer = async (
  payload: EstimatedOfferData
): Promise<PostEstimatedOfferResponse | null> => {
  const endpoint = getUrl(ApiResources.estimatedOffer).replace('{dealId}', payload.dealId);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(
      `${endpoint}?contactId=${payload.contactId}&offerType=${payload.offerType}`,
      request
    );

    return processResponse(response);
  } catch (err) {
    logger.error('postEstimatedOffer error', err);
    return null;
  }
};
