import IMask from 'imask';

export default class SSNMask extends IMask.MaskedRegExp {
  private re: RegExp;
  private char: string;
  // eslint-disable-next-line
  private _value: any;

  // eslint-disable-next-line
  constructor(_: any) {
    super(_);
    this.re = /\d/;
    this.char = '*';
  }

  /**
   * Returns *
   */
  // @ts-ignore
  get value() {
    return this._value === '' ? '' : this.char;
  }

  /**
   * Return actual value
   */
  // @ts-ignore
  get unmaskedValue() {
    return this._value;
  }

  /**
   * @internal
   */
  // @ts-ignore
  _update(_opts) {
    // do nothing
  }

  /**
   * Validates internal value
   * @internal
   */
  // @ts-ignore
  validate(_) {
    return this._value.search(this.re) >= 0;
  }

  /**
   * Return actual value
   * @internal
   */
  extractInput() {
    return this._value;
  }
}
