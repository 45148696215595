import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import EstimatedCreditScoreForm, {
  Props,
} from '../components/EstimatedCreditScoreForm/EstimatedCreditScoreForm';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const CreditScoreFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    actions,
    creditScore: phoenixRoot.creditScore as Props['creditScore'],
    creditSummary: phoenixRoot.creditSummary as Props['creditSummary'],
  };

  return <EstimatedCreditScoreForm {...props} />;
};

export default CreditScoreFormContainer;
