import * as Yup from 'yup';
import i18next from '../utils/i18n';

const schema = () =>
  Yup.object().shape({
    year: Yup.string().required(i18next.t('vehicleNoVin.yearError') as string),
    make: Yup.string().required(i18next.t('vehicleNoVin.makeError') as string),
    model: Yup.string().required(i18next.t('vehicleNoVin.modelError') as string),
    mileage: Yup.string().required(i18next.t('vehicleNoVin.mileageError') as string),
  });

export default schema;
