import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/Info';

import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import CreditScoreFormContainer from '../../containers/CreditScoreFormContainer';
import Header from '../../components/Header/Header';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';

const CreditScoreInfoIcon = styled(InfoIcon)(props => ({
  color: props.theme.palette.teal.main,
  fontSize: 16,
  alignSelf: 'start',
  marginTop: '2px',
  marginLeft: '-2px',
}));

const EstimatedCreditScore = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">
          {t('estimatedCredit.title')}
          <Tooltip arrow title={t('estimatedCredit.tooltip')}>
            <IconButton>
              <CreditScoreInfoIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <CreditScoreFormContainer />
      </ScreenContainer>
      <RecaptchaLogo />
    </>
  );
};

export default EstimatedCreditScore;
