import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import LoanMatchForm, { Props } from '../components/LoanMatchForm/LoanMatchForm';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const LoanMatchFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    actions,
    creditSummary: phoenixRoot.creditSummary as Props['creditSummary'],
    estimatedOffers: phoenixRoot.estimatedOffers as Props['estimatedOffers'],
    progress: phoenixRoot.progress as Props['progress'],
    selectedAsset: phoenixRoot.selectedAsset as Props['selectedAsset'],
  };

  return <LoanMatchForm {...props} />;
};

export default LoanMatchFormContainer;
