import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';

import { AppActions } from '../../store/configureStore';
import { Typography } from '@mui/material';
import ContainerFooter from '../ContainerFooter/ContainerFooter';
import CustomButton from '../CustomButton/CustomButton';
import { useGetEstimatedOffers } from '../../utils/useGetEstimatedOffers';
import { usePostProgressHistory } from '../../utils/usePostProgressHistory';
import useEffectOnce from '../../utils/useEffectOnce';

export interface Props {
  actions: AppActions;
  creditSummary: {
    ficoDetails: { name: string; scoreType: string; score: string }[];
    loanDetails: {
      lender: string;
      currentBalance: number;
      openDate: string;
      payment: number;
      remainingTerm: number;
    }[];
  };
  estimatedOffers: {
    data?: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    }[];
    errors?: { [key: string]: string };
  };
  progress?: {
    data: {
      completedScreen: string;
      nextScreen: string;
    };
  };
  selectedAsset: {
    data?: {
      lienHolderId: string;
      loanInstitution: string;
      currentMonthlyPayment: number;
      estimatedLoanBalance: number;
      remainingTerm: number;
    }[];
    errors?: { [key: string]: string };
  };
}

interface LoanDetails {
  lender: string;
  openDate: string;
  payment: number;
  currentBalance: number;
  remainingTerm: number;
}

const LoanBalanceDisclaimer = styled.span<{ theme: Theme }>`
  color: ${({ theme }) => theme.palette.gray.main};
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.15px;
  padding-bottom: 16px;
`;

const NoLoanGuide = styled.span<{ theme: Theme }>`
  color: ${({ theme }) => theme.palette.teal.main};
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  text-align: center;

  :hover {
    text-decoration: underline;
  }
`;

const LoanDetailsColumn = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

const LoanItemIcon = styled.span`
  position: absolute;
  right: 15px;
  min-width: 30px;
`;

const LoanListItemButton = styled(ListItemButton)(({ theme: { palette } }) => {
  const hoverProps = {
    backgroundColor: palette.lightGreen.main,
    color: palette.black.main,
  };
  return {
    marginTop: '10px',
    boxSizing: 'border-box',
    border: '1px solid #00AA8F',
    borderRadius: '4px',
    '&.Mui-selected': {
      backgroundColor: palette.green.main,
      color: palette.white.main,
      '&:hover': hoverProps,
    },
    '&:hover': hoverProps,
    '@media (max-width: 600px)': {
      '&.Mui-selected': {
        backgroundColor: palette.green.main,
        color: palette.white.main,
        '&:hover': {
          backgroundColor: palette.green.main,
          color: palette.white.main,
        },
      },
      '&:hover': {
        backgroundColor: palette.white.main,
        color: palette.black.main,
      },
    },
  };
});

const LoanEntry = ({ loan }: { loan: LoanDetails }) => {
  const { t } = useTranslation();
  const { lender, openDate, payment, currentBalance, remainingTerm } = loan;
  const titleProps = { fontWeight: 'bold', fontSize: '18px', fontFamily: 'Century Gothic Pro' };
  const detailsProps = { fontFamily: 'Open Sans' };
  const paymentProps = { marginTop: '9px', fontFamily: 'Open Sans' };
  const numberOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

  return (
    <LoanDetailsColumn>
      <Typography sx={titleProps}>{lender}</Typography>
      <Typography sx={detailsProps}>{`${t('loanMatch.openedOn')} ${new Date(
        openDate
      ).toDateString()}`}</Typography>

      <Typography sx={paymentProps}>{`$${Number(payment).toLocaleString('en', numberOptions)} ${t(
        'loanMatch.monthlyPayment'
      )}`}</Typography>
      <Typography sx={detailsProps}>{`$${Number(currentBalance).toLocaleString(
        'en',
        numberOptions
      )} ${t('loanMatch.estimatedBalance')}`}</Typography>
      <Typography sx={detailsProps}>{`${remainingTerm} ${t(
        'loanMatch.monthsRemaining'
      )}`}</Typography>
    </LoanDetailsColumn>
  );
};

const LoanMatchForm = (props: Props) => {
  const { actions, creditSummary, estimatedOffers, progress, selectedAsset } = props;
  const { t } = useTranslation();

  const theme = useTheme();
  const [getEstimatedOffers] = useGetEstimatedOffers({ actions, estimatedOffers });
  const [postProgressHistory] = usePostProgressHistory({
    actions,
    progress: progress?.data,
    screen: 'LoanDetails',
    nextScreen: 'MissingLoanDetails',
    navigateTo: '/loan-info',
  });
  const [checked, setChecked] = useState<number>(-1);
  const [getOffers, setGetOffers] = useState<boolean>(false);

  useEffectOnce(() => actions.analyticsTrackPage('LoanMatch'));

  useEffect(() => {
    if (selectedAsset.data && !getOffers) {
      setGetOffers(true);
      getEstimatedOffers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset.data]);

  const noLoanNavigation = () => {
    postProgressHistory();
    actions.analyticsTrackClick({ eventType: 'I Do Not See My Loan Clicked' });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { loanDetails = [] } = creditSummary;
    const selectedLoan = loanDetails[checked];
    actions.analyticsTrackFormSubmit({ eventType: 'Loan Details Form Submit', ...selectedLoan });
    actions.phoenixPostAsset({ ...selectedLoan, isLoanManuallyEntered: false });
  };

  const { loanDetails = [] } = creditSummary;
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1.5}>
        <List component="nav" key="nav-conditions">
          {loanDetails.map((loan, index) => {
            const labelId = `checkboxCondition${index}`;
            const isSelected = checked === index;
            return (
              <Fragment key={`fragment-${loan.lender}-${loan.payment}`}>
                <LoanListItemButton
                  id={labelId}
                  key={labelId}
                  onClick={() => setChecked(index)}
                  selected={isSelected}
                >
                  <LoanEntry loan={loan} />
                  <LoanItemIcon>
                    {isSelected && <CheckIcon sx={{ color: 'white.main' }} />}
                  </LoanItemIcon>
                </LoanListItemButton>
              </Fragment>
            );
          })}
        </List>
      </Stack>
      <ContainerFooter>
        <NoLoanGuide onClick={noLoanNavigation}>{t('loanMatch.noLoan')}</NoLoanGuide>
        <CustomButton type="submit" isValid={checked > -1}>
          {t('loanMatch.continue')}
        </CustomButton>
        <LoanBalanceDisclaimer theme={theme}>{t('loanMatch.disclaimer')}</LoanBalanceDisclaimer>
      </ContainerFooter>
    </form>
  );
};

export default LoanMatchForm;
