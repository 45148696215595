// import i18next from '../../utils/i18n';

// const getCreditScores = () => ({
//   below520: `${i18next.t('estimatedCredit.below')} 510`,
//   between510and600: '510-600',
//   between600and750: '600-750',
//   over750: `${i18next.t('estimatedCredit.over')} 750`,
// });

// export default getCreditScores;
import i18next from '../../utils/i18n';

const getCreditScores = () => ({
  //below520: `${i18next.t('estimatedCredit.below')} 510`,
  between300and500: '300-500',
  between501and600: '501-600',
  between601and750: '601-750',
  over750: `${i18next.t('estimatedCredit.over')} 750`,
});

export default getCreditScores;
