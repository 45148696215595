import { getUrl, getRequestHeaders, logger, ApiResources, Methods } from './utils';

export type AddressData = {
  address: '';
  aptNumber: '';
  city: '';
  state: '';
  zipCode: '';
  moveinDate: '';
  moveoutDate?: '';
  residenceType: '';
  monthlyPayment: '';
};

export type AddressResponse =
  | { contactId: string; leadId: string }
  | { message: string; errors: { key: string; value: string }[] };

const mapAddressPayload = (payload: AddressData) => ({
  addresses: [
    {
      streetLine1: payload.address,
      streetLine2: payload.aptNumber,
      zip: payload.zipCode,
      city: payload.city,
      state: payload.state,
    },
  ],
  moveInDate: payload.moveinDate,
  residenceTypes: payload.residenceType,
  monthlyPayment: payload.monthlyPayment,
});

export const postAddress = async (payload: AddressData): Promise<AddressResponse | null> => {
  const endpoint = getUrl(ApiResources.address);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(mapAddressPayload(payload)),
  };

  logger.log('postAddress request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    if (response.status > 204) return null;

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (err) {
    logger.error('postAddress error', err);
    return null;
  }
};
