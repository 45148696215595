import styled from 'styled-components';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Header from '../../components/Header/Header';

import errorSvg from '../../assets/error.svg';
import { useTranslation } from 'react-i18next';
import { AppActions } from '../../store/configureStore';
import useEffectOnce from '../../utils/useEffectOnce';

export type Props = {
  actions: AppActions;
};

const ErrorContainer = styled(Container)`
  background-color: ${props => props.theme.palette.white.main};
  padding: ${props => props.theme.spacing(2)};
  box-shadow: 1;
`;

const ErrorImageContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorCard = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  padding-block: 10px;
  margin-block: 5px;
  width: 85%;
`;

const ErrorImage = styled.img`
  width: 100%;
`;

const ErrorText = styled(Typography)`
  text-align: center;
`;

const PhoneNumber = styled.a`
  cursor: grab;
  text-decoration: none;
`;

const Error = (props: Props) => {
  const { t } = useTranslation();

  useEffectOnce(() => {
    props.actions.analyticsTrackPage('Error');
    props.actions.analyticsTrackSystemError({ errorType: 'type', errorDescription: 'description' });
  });

  const onPhoneClick = () =>
    props.actions.analyticsTrackClick({ eventType: 'Error Screen Phone Number Clicked' });

  return (
    <>
      <Header />
      <ErrorContainer maxWidth="sm">
        <Stack spacing={1}>
          <ErrorImageContainer>
            <ErrorCard>
              <ErrorImage src={errorSvg} />
            </ErrorCard>
          </ErrorImageContainer>
          <ErrorText variant="h1">{t('error.title')}</ErrorText>
          <ErrorText>
            {t('error.startText')}
            <PhoneNumber
              href={'tel:' + t('contactPhoneNumber').replace(/-/g, '')}
              onClick={onPhoneClick}
            >
              {t('contactPhoneNumber')}
            </PhoneNumber>
            {t('error.endText')}
          </ErrorText>
        </Stack>
      </ErrorContainer>
    </>
  );
};

export default Error;
