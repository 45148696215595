import React, { Fragment } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import styled from 'styled-components';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import { ListItemText, Theme, useTheme } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PercentIcon from '@mui/icons-material/Percent';

import ContainerFooter from '../ContainerFooter/ContainerFooter';
import CustomButton from '../CustomButton/CustomButton';
import { AppActions } from '../../store/configureStore';
import { useGetEstimatedOfferSelected } from '../../utils/useGetEstimatedOffer';
import useEffectOnce from '../../utils/useEffectOnce';

export interface Props {
  actions: AppActions;
  estimatedOffers: {
    data: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    }[];
    errors: { [key: string]: string };
  };
  estimatedOfferSelected: {
    data?: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    };
    errors?: { [key: string]: string };
  };
}

const initialValues = {};

const ConditionListItemButton = styled(ListItemButton)(props => {
  const hoverProps = {
    backgroundColor: props.theme.palette.lightGreen.main,
    color: props.theme.palette.black.main,
  };
  return {
    marginTop: '10px',
    boxSizing: 'border-box',
    border: '1px solid #00AA8F',
    borderRadius: '4px',
    margin: '0 0 10px',
    '&.Mui-selected': {
      backgroundColor: props.theme.palette.green.main,
      color: props.theme.palette.white.main,
      '&:hover': {
        backgroundColor: props.theme.palette.green.main,
        color: props.theme.palette.white.main,
      },
    },
    '&:hover': hoverProps,
    '@media (max-width: 600px)': {
      '&.Mui-selected': {
        backgroundColor: props.theme.palette.green.main,
        color: props.theme.palette.white.main,
        '&:hover': {
          backgroundColor: props.theme.palette.green.main,
          color: props.theme.palette.white.main,
        },
      },
      '&:hover': {
        backgroundColor: props.theme.palette.white.main,
        color: props.theme.palette.black.main,
      },
    },
  };
});

const AgreementText = styled.span<{ theme: Theme }>`
  color: ${({ theme }) => theme.palette.gray.main};
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const EstimatedOffersForm = ({ actions, estimatedOffers, estimatedOfferSelected }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [getEstimatedOfferSelected] = useGetEstimatedOfferSelected({
    actions,
    estimatedOfferSelected,
  });
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  useEffectOnce(() => actions.analyticsTrackPage('EstimatedOffers'));

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };
  const { handleSubmit, isSubmitting } = useFormik({
    initialValues,
    onSubmit: () => {
      const offer = estimatedOffers.data[selectedIndex];
      getEstimatedOfferSelected({
        dealId: offer.dealId,
        offerType: offer.offerType,
      });
    },
    validateOnMount: true,
  });

  const { data } = estimatedOffers;
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1}>
        {data && (
          <List component="nav" key="nav-conditions">
            {data.map((offer, index) => {
              let icon = null;
              let rateTerms = '';
              let detailTerms = '';
              const cleanOfferType = offer.offerType.replaceAll(' ', '');
              const labelId = `listItem${index}`;

              switch (cleanOfferType) {
                case 'LowestMonthlyPayment':
                  icon = <AttachMoneyIcon />;
                  rateTerms = `$${offer.monthlyPaymentAmount}/month`;
                  detailTerms = `${offer.term} Months at ${offer.rate}% Rate`;
                  break;

                case 'LowestInterestRate':
                  icon = <PercentIcon />;
                  rateTerms = `${offer.rate}% Rate`;
                  detailTerms = `$${offer.monthlyPaymentAmount}/month for ${offer.term} Months`;
                  break;

                case 'ShortestTerm':
                  icon = <HourglassEmptyIcon />;
                  rateTerms = `${offer.term} Months`;
                  detailTerms = `$${offer.monthlyPaymentAmount}/month at ${offer.rate}% Rate`;
                  break;
              }

              return (
                <Fragment key={`fragment-${cleanOfferType}`}>
                  <ConditionListItemButton
                    id={labelId}
                    key={labelId}
                    selected={selectedIndex === index}
                    onClick={event => handleListItemClick(event, index)}
                  >
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              fontFamily: 'Century Gothic Pro',
                              fontSize: '18px',
                              fontWeight: 'bold',
                              lineHeight: '24px',
                              margin: '0 0 10px -4px',
                              color: selectedIndex === index ? 'white.main' : 'green.main',
                            }}
                          >
                            {icon} {offer.offerType}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: 'Century Gothic Pro',
                              fontSize: '24px',
                              fontWeight: 'bold',
                              lineHeight: '24px',
                            }}
                          >
                            {rateTerms}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ 'font-family': 'Open Sans', margin: '2px 0 0' }}
                          >
                            {detailTerms}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemIcon sx={{ justifyContent: 'right', minWidth: '30px' }}>
                      {selectedIndex === index && <CheckIcon sx={{ color: 'white.main' }} />}
                    </ListItemIcon>
                  </ConditionListItemButton>
                </Fragment>
              );
            })}
          </List>
        )}

        <AgreementText theme={theme}>{t('estimatedOffers.agreement')}</AgreementText>
      </Stack>
      <ContainerFooter>
        <CustomButton type="submit" isValid={selectedIndex !== -1} isSubmitting={isSubmitting}>
          {t('estimatedOffers.continue')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default EstimatedOffersForm;
