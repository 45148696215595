import { fromJS, Map, is } from 'immutable';
import { defaultMemoize, createSelectorCreator } from 'reselect';
import { Action, handleActions } from 'redux-actions';

import { RootStateImmutable, RootState, PayloadAction } from '../configureStore';
import ActionTypes from '../../actions/actionTypes';

import { get } from 'lodash';

export const initialState = Map<string, unknown>(
  fromJS({
    ui: {
      isLoading: false,
      loadingImage: '',
      recaptchaToken: '',
    },
    router: {
      path: '',
      pageName: {
        current: null,
        previous: null,
      },
    },
    error: null,
  })
);

const resetNode = (state: RootStateImmutable, path: string) =>
  state.setIn(path, initialState.getIn(path));

const setIsLoadingSuccess = (state: RootStateImmutable, { payload }: PayloadAction<unknown>) => {
  if (get(payload, 'isLoading', false) === state.getIn(['ui', 'isLoading'])) return state;
  return state
    .setIn(['ui', 'isLoading'], get(payload, 'isLoading', false))
    .setIn(
      ['ui', 'loadingImage'],
      get(payload, 'loadingImage', state.getIn(['ui', 'loadingImage']))
    );
};

const resetAppError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  resetNode(state, payload);

const setError = (state: RootStateImmutable, { payload }: PayloadAction<string>) => {
  return state.setIn(['error'], payload);
};

const setRecaptchaToken = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['ui', 'recaptchaToken'], payload);

const handlers = {
  [ActionTypes.SET_IS_LOADING_SUCCESS]: setIsLoadingSuccess,
  [ActionTypes.RESET_APP_ERROR]: resetAppError,
  [ActionTypes.SET_ERROR]: setError,
  [ActionTypes.SET_RECAPTCHA_TOKEN]: setRecaptchaToken,
};

const reduce = handleActions(handlers, initialState);

const rehydrate = (state: RootStateImmutable) => initialState.merge({ ...state });

const reducer = (state: RootStateImmutable, action: Action<string>) =>
  Map.isMap(state) || !state ? reduce(state, action) : rehydrate(state);

const getAppRoot = (state: RootState) => state.app;

const createImmutableSelector = createSelectorCreator(defaultMemoize, is);

// @ts-ignore
const appRoot = createImmutableSelector([getAppRoot], (app: RootStateImmutable) => app.toJS());

export default reducer;

export const selectors = { appRoot };
