import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import CurrentAddressForm, { Props } from '../components/AddressForm/CurrentAddressForm';
import { useAppSelector, useAppDispatch } from '../store/hooks';

const CurrentAddressFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    newaddress: phoenixRoot.newaddress as Props['newaddress'],
    actions,
  };

  return <CurrentAddressForm {...props} />;
};

export default CurrentAddressFormContainer;
