import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ReactComponent as CallIcon } from '../../assets/call.svg';
import { AppActions } from '../../store/configureStore';

const Title = styled(Typography)(({ theme }) => ({
  height: '36px',
  fontSize: '32px',
  pointerEvents: 'none',
  '& > strong': {
    color: theme.palette.green.main,
    fontStyle: 'italic',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  marginBottom: '24px',
  marginTop: '12px',
}));

const CallIconLabelStyled = styled(CallIcon)(({ theme }) => ({
  color: theme.palette.teal.main,
  pointerEvents: 'none',
  height: '36px',
  minWidth: '36px',
}));

const CallUsText = styled.a(props => ({
  color: props.theme.palette.teal.main,
  cursor: 'grab',
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '22px',
  letterSpacing: '0.46px',
  margin: '0 0 2px 15px',
  textDecoration: 'none',
}));

export type FailRecaptchaProps = {
  actions: AppActions;
};

const FailRecaptcha = ({ actions }: FailRecaptchaProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    actions.analyticsTrackPage('GoogleReCaptcha');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title variant="h1">
        <Trans>{t('failRecaptcha.title')}</Trans>
      </Title>
      <Description variant="body2">{t('failRecaptcha.text')}</Description>
      <Typography variant="h2" sx={{ fontSize: '18px', lineHeight: '24px', margin: '0 0 12px' }}>
        {t('failRecaptcha.subtitle')}
      </Typography>

      <List sx={{ margin: '5px 0 20px', padding: '0' }}>
        <ListItem sx={{ padding: '0' }}>
          <CallIconLabelStyled />
          <Stack spacing={0}>
            <CallUsText href={'tel:' + t('contactPhoneNumber').replace(/-/g, '')}>
              {t('failRecaptcha.callUs')} {t('contactPhoneNumber')}
            </CallUsText>
            <Typography variant="body2" sx={{ fontSize: '16px', marginLeft: '15px' }}>
              {t('failRecaptcha.callUsDescription')}
            </Typography>
          </Stack>
        </ListItem>
      </List>
    </>
  );
};

export default FailRecaptcha;
