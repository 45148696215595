import { useMemo, useState } from 'react';
import loadingSvg0 from '../assets/loading-variant-0.svg';
import loadingSvg1 from '../assets/loading-variant-1.svg';
import loadingSvg2 from '../assets/loading-variant-2.svg';
import loadingSvg3 from '../assets/loading-variant-3.svg';

export const loadingImageList = [loadingSvg0, loadingSvg1, loadingSvg2, loadingSvg3];

export const useShowLoadingAnimation = (loading: boolean): boolean => {
  const [isShowingAnimation, setIsShowingAnimation] = useState(false);
  const isLoading: boolean = useMemo(() => {
    if (loading && !isShowingAnimation) {
      setIsShowingAnimation(true);
      setTimeout(() => setIsShowingAnimation(false), 3000);
    }
    return loading || isShowingAnimation;
  }, [loading, isShowingAnimation]);

  return isLoading;
};
