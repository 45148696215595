import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import AboutYouFormContainer from '../../containers/AboutYouFormContainer';
import Header from '../../components/Header/Header';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';

const AboutMe = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('aboutYou.title')}</Typography>
        <p>{t(['aboutYou.upgradeLoan'])}</p>
        <AboutYouFormContainer />
      </ScreenContainer>
      <RecaptchaLogo />
    </>
  );
};

export default AboutMe;
