import Header from '../../components/Header/Header';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import OffRampContainer from '../../containers/OffRampContainer';

const OffRamp = () => {
  return (
    <>
      <Header />
      <ScreenContainer>
        <OffRampContainer />
      </ScreenContainer>
    </>
  );
};

export default OffRamp;
