import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Header from '../../components/Header/Header';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import EstimatedOffersFormContainer from '../../containers/EstimatedOffersFormContainer';

const EstimatedOffers = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('estimatedOffers.title')}</Typography>
        <p>{t('estimatedOffers.subtitle')}</p>
        <EstimatedOffersFormContainer />
      </ScreenContainer>
      <RecaptchaLogo />
    </>
  );
};

export default EstimatedOffers;
