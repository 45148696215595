import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import QualifierForm, { Props } from '../components/QualifierForm/QualifierForm';
import { useAppDispatch } from '../store/hooks';

const QualifierFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);

  const props: Props = {
    actions,
  };

  return <QualifierForm {...props} />;
};

export default QualifierFormContainer;
