import { getUrl, getRequestHeaders, logger, ApiResources, Methods } from './utils';

export type ProgressHistoryDetails = {
  screen: string;
  isCompleted: boolean;
  vinNumber?: string;
  assetId?: string;
  isVinEntered?: boolean;
};

export type ProgressHistoryError = { message: string; code: string; details: string };

export type ProgressHistoryResponse = ProgressHistoryDetails | ProgressHistoryError;

export const getProgressHistory = async (
  leadId: string
): Promise<ProgressHistoryResponse | null> => {
  const endpoint = `${getUrl(ApiResources.progressHistory)}/${leadId}`;
  const request = {
    method: Methods.get,
    headers: await getRequestHeaders(),
  };

  logger.log('getProgressHistory request', endpoint, request);
  try {
    const jsonResponse = await fetch(endpoint, request);
    const response = await jsonResponse.json();

    return response;
  } catch (err) {
    logger.error('getProgressHistory error', err);
    return null;
  }
};
