import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Header from '../../components/Header/Header';
import ScreenContainer from '../ScreenContainer/ScreenContainer';

type LoadingModalProps = {
  loadingImage: string;
};

const LoadingImage = styled.object``;

const LoadingModalContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingCard = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  padding-block: 10px;
  margin-block: 5px;
  min-width: 318px;
`;

const breatheAnimation = keyframes`
  0% { background-color: #9880ff }
  50%, 100% { background-color: #ebe6ff }
`;

const Dots = styled.span`
  display: inline-flex;
  position: relative;
  margin-left: 20px;
  vertical-align: bottom;
  bottom: 5px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: ${breatheAnimation} 1000ms infinite linear alternate 500ms;
  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
  }
  &:before {
    left: -15px;
    animation: ${breatheAnimation} 1000ms infinite linear alternate;
    animation-delay: 0ms;
  }
  &:after {
    left: 15px;
    animation: ${breatheAnimation} 1000ms infinite linear alternate;
    animation-delay: 1000ms;
  }
`;

const LoadingModal = ({ loadingImage }: LoadingModalProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScreenContainer>
        <Stack spacing={1}>
          <LoadingModalContainer>
            <LoadingCard>
              <LoadingImage type="image/svg+xml" data={loadingImage} />
            </LoadingCard>
          </LoadingModalContainer>

          <Typography variant="h1" sx={{ textAlign: 'center' }}>
            {t('loading.text')}
            <Dots />
          </Typography>

          <Typography sx={{ textAlign: 'center' }}>{t('loading.text2')}</Typography>
        </Stack>
      </ScreenContainer>
    </>
  );
};

export default LoadingModal;
