import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Info } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { QualifierOption } from "../../shared/constants/qualifierOptions";

const DisqualifiedList: React.FC<{ options: QualifierOption[] }> = ({
  options,
}) => {
  return (
    <List>
      {options.map((option: QualifierOption) => (
        <ListItem
          disablePadding
          key={option.label}
          sx={{
            backgroundColor: "lighterGreen.main",
            borderColor: "green.main",
            borderWidth: 1,
            borderStyle: "solid",
            minHeight: 45,
            pl: 1.5,
            pr: 1.5,
            mb: 1,
          }}
        >
          <ListItemText
            primary={option.message}
            primaryTypographyProps={{
              sx: { color: "teal.main", fontWeight: 700, pr: 1 },
            }}
          />
          <Tooltip title={option.tooltip} enterTouchDelay={100}>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Info sx={{ color: "teal.main", height: 14, width: 14 }} />
            </ListItemIcon>
          </Tooltip>
        </ListItem>
      ))}
    </List>
  );
};

export default DisqualifiedList;
