import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import MainContainer from './containers/MainContainer';
import reportWebVitals from './reportWebVitals';

import configureStore from './store/configureStore';

import './utils/i18n';

const store = configureStore({});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/api/browser');
  worker.start();
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MainContainer />
    </Provider>
  </React.StrictMode>
);

// reportWebVitals(console.log))
reportWebVitals();
