import Button, { ButtonProps } from '@mui/material/Button';

type Props = ButtonProps & { isValid?: boolean; isSubmitting?: boolean };

const CustomButton = ({ isSubmitting = false, isValid = true, ...props }: Props) => {
  return (
    <Button
      variant="contained"
      disabled={!isValid || isSubmitting}
      color={!isValid || isSubmitting ? 'neutral' : 'yellow'}
      fullWidth={true}
      {...props}
      sx={{
        color: !isValid || isSubmitting ? 'darkGray.main' : 'black.main',
        margin: '16px 0',
        ...props.sx,
      }}
    />
  );
};

export default CustomButton;
