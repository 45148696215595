import { getUrl, getRequestHeaders, logger, ApiResources, Methods } from './utils';

type PostProgressHistoryPayload = {
  isVinEntered?: boolean;
  leadId: string;
  completedScreen: {
    screen: string;
    createdTimeUtc: string;
  };
  nextScreen: string;
};

export const postProgressHistory = async (payload: PostProgressHistoryPayload): Promise<null> => {
  const endpoint = getUrl(ApiResources.progressHistory);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(payload),
  };

  logger.log('postProgressHistory request', endpoint, request);
  try {
    const jsonResponse = await fetch(endpoint, request);
    const response = await jsonResponse.json();

    return response;
  } catch (err) {
    logger.error('postProgressHistory error', err);
    return null;
  }
};
