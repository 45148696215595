import { useEffect, useRef } from 'react';

const useEffectOnce = (effectFunction: () => void) => {
  const useEffectCalled = useRef<boolean>(false);

  useEffect(() => {
    if (!useEffectCalled.current) {
      effectFunction();
      useEffectCalled.current = true;
    }
  }, [effectFunction]);
};

export default useEffectOnce;
