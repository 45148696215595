import { FormEvent, KeyboardEvent } from 'react';

export const onInputNumbersOnly = (e: FormEvent<HTMLInputElement>) => {
  (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/\D/g, '');
};

// KeyDown event to only accept numbers and one dot
export const onKeyDownCurrency = (e: KeyboardEvent<HTMLDivElement>) => {
  const hasDot = (e.target as HTMLInputElement).value.indexOf('.') > -1;
  const currentInput = e.key;
  if (currentInput && currentInput.length === 1 && !!currentInput.match(/[^0-9.]/))
    e.preventDefault();
  if (currentInput === '.' && hasDot) e.preventDefault();
};

// Input event to remove extra numbers after 2 decimals and add commas
export const onInputCurrency = (e: FormEvent<HTMLInputElement>) => {
  const currentValue = (e.target as HTMLInputElement).value.replaceAll(/[^0-9.]/g, '');
  const dotIndex = currentValue.indexOf('.');
  let nextValue =
    dotIndex !== -1
      ? currentValue.substring(dotIndex, Math.min(dotIndex + 3, currentValue.length))
      : '';
  const maxValue = (dotIndex === -1 ? currentValue.length : dotIndex) - 1;
  for (let i = maxValue; i >= 0; i--) {
    const difference = maxValue - i;
    if (difference && difference % 3 === 0) {
      nextValue = `,${nextValue}`;
    }
    nextValue = `${currentValue[i]}${nextValue}`;
  }
  (e.target as HTMLInputElement).value = nextValue;
};
