import * as Yup from 'yup';
import i18next from '../utils/i18n';

const schema = () =>
  Yup.object().shape({
    address: Yup.string().required(i18next.t('addressForm.schema.currentAddress') as string),
    zipCode: Yup.string()
      .required(i18next.t('addressForm.schema.zipCode') as string)
      .max(5, i18next.t('addressForm.schema.zipCodeMax') as string),
    city: Yup.string().required(i18next.t('addressForm.schema.city') as string),
    state: Yup.string().required(i18next.t('addressForm.schema.state') as string),
    moveinDate: Yup.string().required(i18next.t('addressForm.schema.moveinDate') as string),
    monthlyPayment: Yup.string().required(i18next.t('addressForm.schema.monthlyPayment') as string),
    residenceType: Yup.string().required(i18next.t('addressForm.schema.residenceType') as string),
  });

export default schema;
