import { Fragment, MouseEvent } from 'react';
import styled from 'styled-components';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import { ListItemText } from '@mui/material';

import Typography from '@mui/material/Typography';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PercentIcon from '@mui/icons-material/Percent';

export type EstimatedOfferProps = {
  dealId: string;
  rate: number;
  monthlyPaymentAmount: number;
  term: number;
  offerType: string;
};

type Props = {
  handleListItemClick?: MouseEvent<HTMLDivElement, MouseEvent>;
  isSelected?: boolean;
  labelId?: string;
  offer: EstimatedOfferProps;
};

const OfferItem = styled(ListItem)(props => ({
  marginTop: '10px',
  boxSizing: 'border-box',
  border: '1px solid #00AA8F',
  borderRadius: '4px',
  margin: '0 0 10px',
}));

const DetailTermsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.main,
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  margin: '2px 0 0',
}));

export const EstimatedOffer = ({ offer, isSelected, labelId = '0' }: Props) => {
  let icon = null;
  let rateTerms = '';
  let detailTerms = '';
  const cleanOfferType = offer.offerType && offer.offerType.replaceAll(' ', '');

  switch (cleanOfferType) {
    case 'LowestMonthlyPayment':
      icon = <AttachMoneyIcon />;
      rateTerms = `$${offer.monthlyPaymentAmount}/month`;
      detailTerms = `${offer.term} Months at ${offer.rate}% Rate`;
      break;

    case 'LowestInterestRate':
      icon = <PercentIcon />;
      rateTerms = `${offer.rate}% Rate`;
      detailTerms = `$${offer.monthlyPaymentAmount}/month for ${offer.term} Months`;
      break;

    case 'ShortestTerm':
      icon = <HourglassEmptyIcon />;
      rateTerms = `${offer.term} Months`;
      detailTerms = `$${offer.monthlyPaymentAmount}/month at ${offer.rate}% Rate`;
      break;

    default:
      rateTerms = `$${offer.monthlyPaymentAmount}/month`;
      detailTerms = `${offer.term} Months at ${offer.rate}% Rate`;
      break;
  }

  return (
    <OfferItem key={`fragment-${labelId}`}>
      <ListItemText
        primary={
          <Fragment>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                fontFamily: 'Century Gothic Pro',
                fontSize: '18px',
                fontWeight: 'bold',
                lineHeight: '20px',
                margin: '0 0 10px -4px',
                color: isSelected ? 'white.main' : 'green.main',
              }}
            >
              {icon} {offer.offerType}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Century Gothic Pro',
                fontSize: '24px',
                fontWeight: 'bold',
                lineHeight: '24px',
              }}
            >
              {rateTerms}
            </Typography>
            <DetailTermsText variant="body2">{detailTerms}</DetailTermsText>
          </Fragment>
        }
      />
      <ListItemIcon sx={{ justifyContent: 'right', minWidth: '30px' }}>
        {isSelected && <CheckIcon sx={{ color: 'white.main' }} />}
      </ListItemIcon>
    </OfferItem>
  );
};
