import { getUrl, getRequestHeaders, logger, ApiResources, Methods, processResponse } from './utils';

export type CreditScoreData = {
  leadId: string;
  contactId: string;
  scoreRange: string;
};

export type CreditScoreResponse =
  | {
      bureauType: string;
      ficoDetails: { name: string; scoreType: string; score: string }[];
      dateReported: string;
      estimatedScore: number;
    }
  | { message: string; errors: { key: string; value: string }[] };

const mapCreditScorePayload = (payload: CreditScoreData) => ({
  leadId: payload.leadId,
  contactId: payload.contactId,
  scoreRange: payload.scoreRange,
});

export const postCreditScore = async (
  payload: CreditScoreData
): Promise<CreditScoreResponse | null> => {
  const endpoint = getUrl(ApiResources.creditScore).replace('{contactId}', payload.contactId);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(mapCreditScorePayload(payload)),
  };
  logger.log('postCreditScore request', endpoint, request);

  try {
    const response = await fetch(endpoint, request);
    const newResponse = await processResponse(response);
    if (response.status > 204) return newResponse;
    return newResponse ? (newResponse as unknown as CreditScoreResponse[])[0] : newResponse;
  } catch (err) {
    logger.error('postCreditScore error', err);
    return null;
  }
};
