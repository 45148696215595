const appActions = {
  LOAD_APP: 'dx:app:loadApp',
  LAUNCH_APP: 'dx:app:launchApp',
  SET_IS_LOADING: 'dx:app:setIsLoading',
  SET_IS_LOADING_NO_WAIT: 'dx:app:setIsLoadingNoWait',
  SET_IS_LOADING_SUCCESS: 'dx:app:setIsLoadingSuccess',
  SET_ERROR: 'dx:app:setError',
  SET_RECAPTCHA_TOKEN: 'dx:app:setToken',
  RESET_APP_ERROR: 'dx:app:resetAppError',
};

const analyticsActions = {
  ANALYTICS_TRACK_APP_LOADED: 'dx:analytics:appLoaded',
  ANALYTICS_TRACK_CLICK: 'dx:analytics:trackClick',
  ANALYTICS_TRACK_FORM_SUBMIT: 'dx:analytics:trackFormSubmit',
  ANALYTICS_TRACK_PAGE: 'dx:analytics:trackPage',
  ANALYTICS_TRACK_SYSTEM_ERROR: 'dx:analytics:trackSystemError',
};

const phoenixActions = {
  PHOENIX_GET_CREDIT_SUMMARY: 'dx:phoenix:getCreditSummary',
  PHOENIX_GET_CREDIT_SUMMARY_SUCCESS: 'dx:phoenix:getCreditSummarySuccess',
  PHOENIX_GET_CREDIT_SUMMARY_ERROR: 'dx:phoenix:getCreditSummaryError',
  PHOENIX_GET_YEARS: 'dx:phoenix:getYears',
  PHOENIX_GET_YEARS_SUCCESS: 'dx:phoenix:getYearsSuccess',
  PHOENIX_GET_YEARS_ERROR: 'dx:phoenix:getYearsError',
  PHOENIX_GET_MAKES: 'dx:phoenix:getMakes',
  PHOENIX_GET_MAKES_SUCCESS: 'dx:phoenix:getMakesSuccess',
  PHOENIX_GET_MAKES_ERROR: 'dx:phoenix:getMakesError',
  PHOENIX_GET_MODELS: 'dx:phoenix:getModels',
  PHOENIX_GET_MODELS_SUCCESS: 'dx:phoenix:getModelsSuccess',
  PHOENIX_GET_MODELS_ERROR: 'dx:phoenix:getModelsError',
  PHOENIX_GET_VEHICLE_INFO: 'dx:phoenix:getVehicleInfoFromVIN',
  PHOENIX_GET_VEHICLE_INFO_SUCCESS: 'dx:phoenix:getVehicleInfoFromVINSuccess',
  PHOENIX_GET_VEHICLE_INFO_ERROR: 'dx:phoenix:getVehicleInfoFromVINError',
  PHOENIX_CLEAR_VEHICLE_INFO: 'dx:phoenix:clearVehicleInfo',
  PHOENIX_POST_CONTACT: 'dx:phoenix:postContact',
  PHOENIX_POST_CONTACT_SUCCESS: 'dx:phoenix:postContactSuccess',
  PHOENIX_POST_CONTACT_ERROR: 'dx:phoenix:postContactError',
  PHOENIX_UPDATE_SSN: 'dx:phoenix:updateSSN',
  PHOENIX_UPDATE_SSN_SUCCESS: 'dx:phoenix:updateSSNSuccess',
  PHOENIX_UPDATE_SSN_ERROR: 'dx:phoenix:updateSSNError',
  PHOENIX_POST_CREDIT_SCORE: 'dx:phoenix:postCreditScore',
  PHOENIX_POST_CREDIT_SCORE_SUCCESS: 'dx:phoenix:postCreditScoreSuccess',
  PHOENIX_POST_CREDIT_SCORE_ERROR: 'dx:phoenix:postCreditScoreError',
  PHOENIX_POST_QUALIFIERS: 'dx:phoenix:postQualifiers',
  PHOENIX_POST_QUALIFIERS_SUCCESS: 'dx:phoenix:postQualifiersSuccess',
  PHOENIX_PUT_ASSET: 'dx:phoenix:putAsset',
  PHOENIX_PUT_ASSET_SUCCESS: 'dx:phoenix:putAssetSuccess',
  PHOENIX_PUT_ASSET_ERROR: 'dx:phoenix:putAssetError',
  PHOENIX_POST_ASSETS: 'dx:phoenix:postAssets',
  PHOENIX_POST_ASSETS_SUCCESS: 'dx:phoenix:postAssetsSuccess',
  PHOENIX_POST_ASSETS_ERROR: 'dx:phoenix:postAssetsError',
  PHOENIX_GET_ESTIMATED_OFFERS: 'dx:phoenix:getEstimatedOffers',
  PHOENIX_GET_ESTIMATED_OFFERS_SUCCESS: 'dx:phoenix:getEstimatedOffersSuccess',
  PHOENIX_GET_ESTIMATED_OFFERS_ERROR: 'dx:phoenix:getEstimatedOffersError',
  PHOENIX_POST_ASSET: 'dx:phoenix:postAsset',
  PHOENIX_POST_ASSET_SUCCESS: 'dx:phoenix:postAssetSuccess',
  PHOENIX_POST_ASSET_ERROR: 'dx:phoenix:postAssetError',
  PHOENIX_POST_ESTIMATED_OFFER: 'dx:phoenix:postEstimatedOffer',
  PHOENIX_POST_ESTIMATED_OFFER_SUCCESS: 'dx:phoenix:postEstimatedOfferSuccess',
  PHOENIX_POST_ESTIMATED_OFFER_ERROR: 'dx:phoenix:postEstimatedOfferError',
  PHOENIX_GET_PROGRESS_HISTORY: 'dx:phoenix:getProgressHistory',
  PHOENIX_GET_PROGRESS_HISTORY_SUCCESS: 'dx:phoenix:getProgressHistorySuccess',
  PHOENIX_GET_PROGRESS_HISTORY_ERROR: 'dx:phoenix:getProgressHistoryError',
  PHOENIX_POST_PROGRESS_HISTORY: 'dx:phoenix:postProgressHistory',
  PHOENIX_POST_PROGRESS_HISTORY_SUCCESS: 'dx:phoenix:postProgressHistorySuccess',
  PHOENIX_POST_LEAD: 'dx:phoenix:postLead',
  PHOENIX_POST_LEAD_SUCCESS: 'dx:phoenix:postLeadSuccess',
  PHOENIX_POST_LEAD_ERROR: 'dx:phoenix:postLeadError',
  PHOENIX_POST_ADDRESS: 'dx:phoenix:postAddress',
  PHOENIX_POST_ADDRESS_SUCCESS: 'dx:phoenix:postAddressSuccess',
  PHOENIX_POST_ADDRESS_ERROR: 'dx:phoenix:postAddressError',
  PHOENIX_POST_CURRENT_ADDRESS: 'dx:phoenix:postCurrentAddress',
  PHOENIX_POST_CURRENT_ADDRESS_SUCCESS: 'dx:phoenix:postCurrentAddressSuccess',
  PHOENIX_POST_CURRENT_ADDRESS_ERROR: 'dx:phoenix:postCurrentAddressError',
};

const actions = {
  ...appActions,
  ...analyticsActions,
  ...phoenixActions,
};

export default actions;
