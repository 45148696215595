import { useEffect } from 'react';
import { AppActions } from '../store/configureStore';
import { useNavigate } from 'react-router';

type Props = {
  actions: AppActions;
  isVinEntered?: boolean;
  navigateTo: string;
  nextScreen: string;
  progress?: {
    completedScreen: string;
    nextScreen: string;
  };
  screen: string;
};

export const usePostProgressHistory = ({
  actions,
  screen,
  nextScreen,
  navigateTo,
  isVinEntered,
  progress,
}: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (progress && progress.completedScreen === screen && progress.nextScreen === nextScreen) {
      navigate(navigateTo, { replace: true });
    }
  }, [progress, navigate, navigateTo, screen, nextScreen]);

  const postProgressHistory = () =>
    actions.phoenixPostProgressHistory({
      completedScreen: {
        screen,
        createdTimeUtc: new Date().toISOString(),
      },
      nextScreen,
      isVinEntered: !!isVinEntered,
    });

  return [postProgressHistory];
};
