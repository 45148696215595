import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import ContainerFooter from '../ContainerFooter/ContainerFooter';
import vehicleMoreInfoSchema from '../../schemas/VehicleMoreInfo';
import { isOver150miles, getMileages } from '../../shared/constants/mileages';
import { getQualifierOptions } from '../../shared/constants/qualifierOptions';
import vehicleIcon from '../../assets/vehicle-side.svg';
import { AppActions } from '../../store/configureStore';
import CustomButton from '../CustomButton/CustomButton';
import { usePostProgressHistory } from '../../utils/usePostProgressHistory';
import useEffectOnce from '../../utils/useEffectOnce';

export interface Props {
  actions: AppActions;
  vehicleInfo: {
    data: { [key: string]: string };
    errors: { [key: string]: string };
    loading: boolean;
  };
  progress?: {
    data: {
      completedScreen: string;
      nextScreen: string;
    };
  };
}

const initialValues = {
  mileage: '',
};

const VehicleYMMCard = styled(Card)(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  height: '90px',
  backgroundColor: props.theme.palette.lighterGray.main,
  placeItems: 'center',
  marginTop: '15px',
  marginBottom: '5px',
}));

const VehicleIconButton = styled.span(props => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  borderRadius: '50%',
  marginLeft: '17px',
  backgroundColor: props.theme.palette.white.main,
  height: '58px',
  width: '58px',
}));

const VehicleIcon = styled.img(props => ({
  color: props.theme.palette.teal.main,
  width: '45%',
  pointerEvents: 'none',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const VehicleYMMText = styled(Typography)(props => ({
  marginLeft: '15px',
}));

const SelectGray = styled(Select)(props => ({
  backgroundColor: props.theme.palette.lighterGray.main,
}));

const FormControlGray = styled(FormControl)(props => ({
  '& .MuiFilledInput-underline:before': {
    borderBottomColor: props.theme.palette.darkGray.main,
  },
}));

const VehicleMoreInfoForm = ({ actions, vehicleInfo: { data }, progress }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const qualifierOptions = getQualifierOptions();
  const mileages = getMileages();
  const isOver10years = (year: string) => +year <= new Date().getFullYear() - 10;
  const [postProgressHistory] = usePostProgressHistory({
    actions,
    progress: progress?.data,
    screen: 'VehicleMoreInfo',
    nextScreen: 'RequestSsn',
    navigateTo: '/request-ssn',
    isVinEntered: true
  });

  useEffectOnce(() => actions.analyticsTrackPage('VehicleMoreInfo'));

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: vehicleMoreInfoSchema(),
      onSubmit: values => {
        const { mileage } = values;
        const assetIsOver10years = isOver10years(data.year.toString());
        const assetIsOver150miles = isOver150miles(mileage.toString());

        actions.phoenixPutAsset({ estimatedMileageRange: mileage });

        if (assetIsOver150miles) {
          const options = [qualifierOptions.Over150kMiles.value];
          const allSelected = options.reduce((a, b) => (b ? `${a}|${b}` : `${a}`));
          actions.phoenixPostQualifiers(options);
          navigate(`/disqualified?selected=${allSelected}`, { replace: true });
        } else if (!assetIsOver10years && !assetIsOver150miles) {
          postProgressHistory();
        }
      },
      validateOnMount: true,
    });

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1.5}>
        <VehicleYMMCard>
          <VehicleIconButton>
            <VehicleIcon src={vehicleIcon} />
          </VehicleIconButton>
          {data ? (
            <VehicleYMMText>
              {data.year} {data.make} {data.model}
            </VehicleYMMText>
          ) : (
            <VehicleYMMText />
          )}
        </VehicleYMMCard>
        <FormControlGray variant="filled" error={touched.mileage && !!errors.mileage}>
          <InputLabel id="mileageLabel">{t('vehicleMoreInfo.form.mileage')}</InputLabel>
          <SelectGray
            id="mileage"
            name="mileage"
            value={values.mileage}
            onChange={handleChange}
            onBlur={handleBlur}
            labelId="mileageLabel"
          >
            {mileages.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </SelectGray>
          <FormHelperText>{touched.mileage && errors.mileage}</FormHelperText>
        </FormControlGray>
      </Stack>
      <ContainerFooter>
        <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
          {t('vehicleMoreInfo.form.continue')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default VehicleMoreInfoForm;
