import * as Yup from 'yup';
import i18next from '../utils/i18n';

const schema = () =>
  Yup.object().shape({
    ssn: Yup.string()
      .matches(
        /^\d{3}-?\d{2}-?\d{4}$/,
        i18next.t('requestSSN.error') as string
      )
      .required(i18next.t('requestSSN.emptyError') as string),
    agreement: Yup.boolean().isTrue(''),
  });

export default schema;
