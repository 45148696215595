import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
`;

const ContainerFooter = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

export default ContainerFooter;
